import React, { useContext, useState } from "react";
import {
  Box,
  Button,
  TextField,
  useTheme,
  CircularProgress,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useNavigate } from "react-router-dom";
import { tokens } from "../../../theme";
import { ValidUserContext } from "../../login/authCheck";

const ContactDetails = ({
  contactDetails,
  onClose,
  refreshContacts,
  setIsEditable,
  isEditable,
  showSnackbar,
}) => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const navigate = useNavigate();
  const { userRoles } = useContext(ValidUserContext);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isDeleting, setIsDeleting] = useState(false); // State to track deleting process

  const handleFormSubmit = async (values, { setSubmitting }) => {
    if (!isEditable) {
      setSubmitting(false);
      return;
    }

    const requestBody = {
      fullName: values.fullName,
      commonName: values.commonName,
      phone: values.phone,
      email: values.email,
      facebook: values.facebook,
      instagram: values.instagram,
      websiteURL: values.websiteURL,
    };

    const url = `https://faculdades-3cket-aefml.oa.r.appspot.com/contacts/update/${contactDetails.ID}`;

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const responseData = await response.text();
      //console.log("Success:", responseData);

      // refreshContacts(); // Atualiza a lista após salvar alterações
      showSnackbar("Documento editado com sucesso!"); // Adicione esta linha
    } catch (error) {
      console.error("Error:", error);
    }

    setIsEditable(false);
    setSubmitting(false);
    onClose(); // Fecha o drawer após salvar
  };

  const handleDelete = async () => {
    setIsDeleting(true); // Set deleting state to true

    const url = `https://faculdades-3cket-aefml.oa.r.appspot.com/contacts/delete/${contactDetails.ID}`;

    try {
      const response = await fetch(url, {
        method: "DELETE",
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error("Failed to delete the contact.");
      }

     // console.log("Contact deleted successfully");
      refreshContacts(); // Atualiza a lista após salvar alterações
      showSnackbar("Contacto removido com sucesso!"); // Adicione esta linha

      onClose(); // Close the drawer after deletion
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsDeleting(false); // Reset deleting state
    }
  };

  const canEditContacts =
    userRoles.includes("SU") || userRoles.includes("Admin");

  return (
    <Box m="20px">
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={{
          fullName: contactDetails.Full_Name || "",
          commonName: contactDetails.Common_Name || "",
          email: contactDetails.Email || "",
          phone: contactDetails.Phone_Number || "",
          facebook: contactDetails.Facebook || "",
          instagram: contactDetails.Instagram || "",
          websiteURL: contactDetails.Website_URL || "",
        }}
        validationSchema={checkoutSchema}
        enableReinitialize
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          resetForm,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="flex"
              gap="20px" // Reduced gap for a smaller look
              flexDirection="column"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                variant={isEditable ? "filled" : "standard"}
                type="text"
                label="Nome"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.fullName}
                name="fullName"
                error={!!touched.fullName && !!errors.fullName}
                helperText={touched.fullName && errors.fullName}
                sx={{ gridColumn: "span 6", fontSize: "0.875rem",border:"1px solid #000", borderRadius:"4px" }}
                InputProps={{
                  readOnly: !isEditable,
                  style: { color: "#000" }, 
                }}
                InputLabelProps={{
                  style: { color: "#000" }, // Cor do label para preto
                }}
              />
            
              <TextField
                fullWidth
                variant={isEditable ? "filled" : "standard"}
                type="text"
                label="Email"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.email}
                name="email"
                error={!!touched.email && !!errors.email}
                helperText={touched.email && errors.email}
                sx={{ gridColumn: "span 6", fontSize: "0.875rem",border:"1px solid #000", borderRadius:"4px" }}
                InputProps={{
                  readOnly: !isEditable,
                  style: { color: "#000" }, 
                }}
                InputLabelProps={{
                  style: { color: "#000" }, // Cor do label para preto
                }}
              />
              <TextField
                fullWidth
                variant={isEditable ? "filled" : "standard"}
                type="text"
                label="Número de telemóvel"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.phone}
                name="phone"
                error={!!touched.phone && !!errors.phone}
                helperText={touched.phone && errors.phone}
                sx={{ gridColumn: "span 6", fontSize: "0.875rem",border:"1px solid #000", borderRadius:"4px" }}
                InputProps={{
                  readOnly: !isEditable,
                  style: { color: "#000" }, 
                }}
                InputLabelProps={{
                  style: { color: "#000" }, // Cor do label para preto
                }}
              />
              <TextField
                fullWidth
                variant={isEditable ? "filled" : "standard"}
                type="text"
                label="Facebook URL"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.facebook}
                name="facebook"
                error={!!touched.facebook && !!errors.facebook}
                helperText={touched.facebook && errors.facebook}
                sx={{ gridColumn: "span 6", fontSize: "0.875rem",border:"1px solid #000", borderRadius:"4px" }}
                InputProps={{
                  readOnly: !isEditable,
                  style: { color: "#000" }, 
                }}
                InputLabelProps={{
                  style: { color: "#000" }, // Cor do label para preto
                }}
              />
              <TextField
                fullWidth
                variant={isEditable ? "filled" : "standard"}
                type="text"
                label="Instagram URL"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.instagram}
                name="instagram"
                error={!!touched.instagram && !!errors.instagram}
                helperText={touched.instagram && errors.instagram}
                sx={{ gridColumn: "span 6", fontSize: "0.875rem",border:"1px solid #000", borderRadius:"4px" }}
                InputProps={{
                  readOnly: !isEditable,
                  style: { color: "#000" }, 
                }}
                InputLabelProps={{
                  style: { color: "#000" }, // Cor do label para preto
                }}
              />
              <TextField
                fullWidth
                variant={isEditable ? "filled" : "standard"}
                type="text"
                label="Website URL"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.websiteURL}
                name="websiteURL"
                error={!!touched.websiteURL && !!errors.websiteURL}
                helperText={touched.websiteURL && errors.websiteURL}
                sx={{ gridColumn: "span 6", fontSize: "0.875rem",border:"1px solid #000", borderRadius:"4px" }}
                InputProps={{
                  readOnly: !isEditable,
                  style: { color: "#000" }, 
                }}
                InputLabelProps={{
                  style: { color: "#000" }, // Cor do label para preto
                }}
              />
            </Box>

            <Box
              display="flex"
              justifyContent="space-between"
              mt="20px"
              width="100%"
            >
              {canEditContacts && !isEditable && (
                <Button
                  onClick={handleDelete}
                  color="error"
                  variant="contained"
                  disabled={isDeleting} // Disable button while deleting
                  startIcon={
                    isDeleting ? (
                      <CircularProgress size={20} color="inherit" />
                    ) : null
                  }
                >
                  {isDeleting ? "A eliminar..." : "Eliminar Contacto"}
                </Button>
              )}

              {isEditable && (
                <Button
                  onClick={() => {
                    resetForm(); // Resets the form to its initial state
                    setIsEditable(false); // Exits edit mode
                  }}
                  color="error"
                >
                  Cancelar Alterações
                </Button>
              )}

              <Button
                onClick={() => {
                  if (!isEditable) {
                    setIsEditable(true); // Torna o formulário editável quando clicado no botão "Editar"
                    // console.log("colocado a editar");
                  } else {
                    handleSubmit(); // Submete o formulário se já estiver editando
                  }
                }}
                sx={{
                  //mb: "1rem", // Add margin-bottom of 1rem
                  backgroundColor:
                    theme.palette.mode === "dark"
                      ? colors.customTheme.primary
                      : colors.customTheme.secondary,
                      style: { color: colors.customTheme.textColor }, // Cor do label para preto
                      "&:hover": {
                    backgroundColor:
                      theme.palette.mode === "dark" ? "#f0f0f0" : "#333", // Hover state color
                  },
                }}
                variant="contained"
                disabled={isSubmitting} // Desabilita o botão enquanto o formulário está sendo submetido
                startIcon={
                  isSubmitting ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : null
                }
              >
                {isSubmitting
                  ? "A guardar..."
                  : isEditable
                  ? "Guardar Alterações"
                  : "Editar"}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const checkoutSchema = yup.object().shape({
  fullName: yup.string().required("Campo obrigatório"),
  //commonName: yup.string().required("required"),
  email: yup.string().required("Campo obrigatório"),
  phone: yup
    .string()
    .matches(phoneRegExp, "Número inválido")
    .required("Campo obrigatório"),
});

export default ContactDetails;
