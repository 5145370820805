import React, { useState, useEffect, useContext } from "react";
import { Box, Button, IconButton, useMediaQuery } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Header from "../../../components/Header";
import { tokens } from "../../../theme";
import { ValidUserContext } from "../../login/authCheck";
import ContactDetails from "../contactDetails/index";
import Form from "../createContact/index"; // Import the create contact form
import CloseIcon from "@mui/icons-material/Close";
import SnackbarComponent from "../../global/Snackbar";

const Contacts = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { userRoles } = useContext(ValidUserContext);
  const [contacts, setContacts] = useState([]);
  const [selectedContact, setSelectedContact] = useState(null); // State for the selected contact
  const [isCreatingContact, setIsCreatingContact] = useState(false); // New state to check if we're creating a contact

  const [isEditable, setIsEditable] = useState(false); // Adicionando o estado de edição aqui
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [overlayOpen, setOverlayOpen] = useState(false);


  useEffect(() => { }, [isEditable]); //

  const fetchContacts = async () => {
    try {
      const response = await fetch(
        "https://faculdades-3cket-aefml.oa.r.appspot.com/contacts",
        {
          headers: {
            "Content-Type": "application/json; charset=UTF-8",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      // Use response.json() to automatically handle UTF-8 encoding
      const data = await response.json();
     // console.log(data);
      setContacts(data.map((item, index) => ({ ...item, id: index })));
    } catch (error) {
      console.error("Failed to fetch contacts:", error);
    }
  };

  useEffect(() => {
    fetchContacts();
  }, []);


  const columns = [
    { field: "ID", headerName: "ID", width: 100 },
    { field: "Full_Name", headerName: "Full Name", width: 300 },
    { field: "Phone_Number", headerName: "Phone Number", width: 150 },
    { field: "Email", headerName: "Email", width: 250 },
  ];

  const handleOpenContactDetails = (params) => {
    setSelectedContact(params.row);
    setIsCreatingContact(false); // Ensure we're not creating a new contact
    setOverlayOpen(true);
  };

  const handleCreateContact = () => {
    setSelectedContact(null); // Clear any selected contact
    setIsCreatingContact(true); // Set to creating contact mode
    setOverlayOpen(true);
  };

  const closeOverlay = () => {
    setOverlayOpen(false);
    setSelectedContact(null);
    setIsCreatingContact(false);
    setIsEditable(false);
  };


  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const canAddContacts =
    userRoles.includes("SU") || userRoles.includes("Admin");

  return (
    <Box width="100%" >
      <Header title="Contactos" subtitle="Listagem de todos os contactos" />
      <Box display="flex" justifyContent="flex-end" alignItems="flex-end">

        {canAddContacts && (
          <Button
            variant="contained"
            onClick={handleCreateContact} // Handle create contact click
            sx={{
              mb: "1rem", // Add margin-bottom of 1rem
              backgroundColor: theme.palette.mode === "dark" ? colors.customTheme.primary : colors.customTheme.secondary,
              color: colors.customTheme.textColor,
              "&:hover": {
                backgroundColor: colors.customTheme.darkPrimary, // Hover state color
              },
            }}
          >
            Adicionar Contacto
          </Button>
        )}
      </Box>

      {/* Main Content (Contacts Table and Drawer) */}
      <Box
        display="flex"
        height="60vh"
        alignContent="center"
        gap="2rem"
        width="100%"
      //marginBottom="6rem"
      >
        <Box
        // sx={{
        //   flexGrow: 1,
        //   width: isSmallScreen ? "100%" : drawerOpen ? "100%" : "100%", // Ajusta a largura com base na condição
        //   //overflow: "hidden",
        // }}
        >
          <Box
            height="100%"
            width="100%"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
                fontSize: "14px",
                borderRadius: "8px",
                boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
                backgroundColor: colors.customTheme.primary,
                color: colors.customTheme.textColor,


              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
                padding: "8px 16px",
                color: colors.customTheme.textColor,

              },
              "& .MuiDataGrid-columnHeaders": {
                color: colors.customTheme.textColor,
                borderBottom: "none",
                fontSize: "14px",
                fontWeight: "bold",
                borderTopLeftRadius: "8px",
                borderTopRightRadius: "8px",
              },
              "& .MuiDataGrid-row:hover": {
                backgroundColor: colors.blueAccent[100],
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.customTheme.background
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.customTheme.primary,
                borderBottomLeftRadius: "8px",
                borderBottomRightRadius: "8px",
              },
              "& .MuiCheckbox-root": {
                color: `${colors.greenAccent[200]} !important`,
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: colors.customTheme.textColor,
              },
              "& .MuiTablePagination-root": {
                color: colors.customTheme.textColor,

              },         "& .MuiSvgIcon-root" : {
                color: colors.customTheme.textColor,
          },
            }}
          >
            <DataGrid
              rows={contacts}
              columns={columns}
              components={{ Toolbar: GridToolbar }}
              onRowClick={(params) => {
                if (!overlayOpen || !isEditable) {
                  handleOpenContactDetails(params);
                } else if (overlayOpen && isEditable) {
                  showSnackbar(
                    "Termine de editar o documento para puder selecionar outro"
                  ); // Adicione esta linha
                }
              }}
            />
          </Box>
          {overlayOpen && (
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                background: "rgba(0, 0, 0, 0.7)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 1000,
                overflowY: "scroll"
              }}
            >
              <div
                style={{
                  background: colors.customTheme.background,
                  padding: "20px",
                  borderRadius: "10px",
                  width: "50vw",
                  height: "70vh",
                  position: "relative",
                  overflowY: "scroll",
                  overflowX: "hidden"
                }}
              >
                <Box
                  bgcolor={
                    theme.palette.mode === "dark"
                      ? colors.customTheme.darkBackground
                      : colors.customTheme.background
                  }
                  p={2}

                  borderRadius="8px"
                  transition="flex 0.3s ease"
                  overflow="auto"

                >
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <h2
                      style={{
                        color: colors.customTheme.textColor,
                      }}
                    >
                      {isCreatingContact ? "Criar Contacto" : "Detalhes do Contacto"}
                    </h2>
                    <IconButton
                      onClick={closeOverlay}
                      sx={{ color: colors.customTheme.textColor }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Box>

                  {/* Render Contact Details if a contact is selected, otherwise render the Form */}
                  {selectedContact ? (
                    <ContactDetails
                      contactDetails={selectedContact} // Pass the selected contact to the form
                      onClose={closeOverlay} // Pass the close function to ContactDetails
                      isEditable={isEditable} // Passa o estado atual para o formulário
                      setIsEditable={setIsEditable}
                      refreshContacts={fetchContacts}
                      showSnackbar={showSnackbar} // Passa a função aqui
                    />
                  ) : (
                    <Form
                      onClose={closeOverlay}
                      refreshContacts={fetchContacts}
                      showSnackbar={showSnackbar} // Passa a função aqui
                    /> // Render the create contact form if no contact is selected
                  )}
                </Box>
              </div>
            </div>
          )}
        </Box>

        {/* Inline Drawer for Contact Details or Create Contact Form */}

      </Box>
      <SnackbarComponent
        open={snackbarOpen}
        message={snackbarMessage}
        onClose={handleSnackbarClose}
      />
    </Box>
  );
};

export default Contacts;
