import React, { useContext, useState } from "react";
import {
  Box,
  Button,
  IconButton,
  CircularProgress,
  TextField,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import InputAdornment from "@mui/material/InputAdornment";
import { MuiFileInput } from "mui-file-input";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { ValidUserContext } from "../../login/authCheck";
import { tokens } from "../../../theme";
import { useTheme } from "@mui/material";

const Form = ({
  documentDetails,
  onClose,
  refreshDocuments,
  setIsEditable,
  isEditable,
  showSnackbar,
}) => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const { userRoles } = useContext(ValidUserContext);
  const [file, setFile] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false); // State to track deleting process
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const initialValues = {
    title: documentDetails?.Title || "",
    file: documentDetails?.FileURL || "", // Initialize the file as the current document URL
  };

  const handleFormSubmit = async (values, { setSubmitting }) => {
    if (!isEditable) {
      setSubmitting(false);
      return;
    }

    const formData = new FormData();
    Object.keys(values).forEach((key) => {
      if (key !== "file") {
        formData.append(key, values[key]);
      }
    });

    if (file) {
      formData.append("file", file);
    }

    const url = documentDetails
      ? `https://faculdades-3cket-aefml.oa.r.appspot.com/ae/update/${documentDetails.ID}`
      : `https://faculdades-3cket-aefml.oa.r.appspot.com/ae/add`;

    try {
      const response = await fetch(url, {
        method: "POST",
        credentials: "include",
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const responseData = await response.text();
      // console.log("Success:", responseData);

      refreshDocuments(); // Atualiza a lista após salvar alterações
      showSnackbar("Documento editado com sucesso!"); // Adicione esta linha
    } catch (error) {
      console.error("Error:", error);
    }

    setIsEditable(false);
    setSubmitting(false);
    onClose(); // Fecha o drawer após salvar
  };

  const handleDelete = async () => {
    setIsDeleting(true); // Set deleting state to true

    const url = `https://faculdades-3cket-aefml.oa.r.appspot.com/ae/delete/${documentDetails.ID}`;

    try {
      const response = await fetch(url, {
        method: "DELETE",
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error("Failed to delete the document.");
      }

     // console.log("Document deleted successfully");
      refreshDocuments(); // Atualiza a lista após deletar o documento
      showSnackbar("Documento removido com sucesso!"); // Adicione esta linha

      onClose(); // Fecha o drawer após deletar
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsDeleting(false); // Reset deleting state
    }
  };

  const handleFileChange = (newFile) => {
    setFile(newFile);
  };

  const renderFileIcon = (fileUrl) => {
    if (!fileUrl) return null;

    const fileExtension = fileUrl.split(".").pop().toLowerCase();
    let icon;

    switch (fileExtension) {
      case "pdf":
        icon = <PictureAsPdfIcon style={{ color: "#000", fontSize: 40 }} />;
        break;
      default:
        icon = <PictureAsPdfIcon style={{ color: "#000", fontSize: 40 }} />;
    }

    return (
      <IconButton
        component="a"
        href={fileUrl}
        target="_blank"
        rel="noopener noreferrer"
      >
        {icon}
      </IconButton>
    );
  };

  const canEditDocuments =
    userRoles.includes("SU") || userRoles.includes("Admin");

  return (
    <Box m="20px">
      <Formik
        initialValues={initialValues}
        validationSchema={checkoutSchema}
        onSubmit={handleFormSubmit}
        enableReinitialize
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting, // Flag for spinner on save
          resetForm,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="flex"
              gap="20px"
              flexDirection="column"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
           <TextField
  fullWidth
  variant="filled"
  sx={{border:"1px solid #000", borderRadius:"4px"}}

  type="text"
  label="Título"
  onBlur={handleBlur}
  onChange={handleChange}
  value={values.title}
  name="title"
  error={!!touched.title && !!errors.title}
  helperText={touched.title && errors.title}
  InputProps={{
    readOnly: !isEditable,
    style: { color: "#000" }, // Cor do texto de input para preto
  }}
  InputLabelProps={{
    style: { color: "#000" }, // Cor do label para preto
  }}
/>


              {/* Render file icon */}
              {documentDetails && renderFileIcon(documentDetails.FileURL)}

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Data"
                sx={{border:"1px solid #000", borderRadius:"4px"}}

                value={documentDetails?.Publication_Date || ""}
                readOnly
                InputProps={{
                  readOnly: !isEditable,
                  style: { color: "#000" }, // Cor do texto de input para preto
                }}
                InputLabelProps={{
                  style: { color: "#000" }, // Cor do label para preto
                }}
              />

              {isEditable && (
                <MuiFileInput
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {" "}
                        {/* Add position="start" */}
                        <AttachFileIcon />
                      </InputAdornment>
                    ),
                    style: { color: "#000" }, // Cor do texto de input para preto

                  }}
                  sx={{
                    border: "1px solid #000", // Borda preta
                    borderRadius: "4px", // Bordas arredondadas
                    color: "#000", // Cor do texto para preto
                    '& .MuiInputBase-input': {
                      color: "#000", // Garante que o texto de entrada também seja preto
                    },
                    '& .MuiInputAdornment-root': {
                      color: "#000", // Garante que o ícone também seja preto
                    },
                  }}
                  placeholder="Escolha um documento"
                  value={file}
                  onChange={handleFileChange}
                />
              )}
            </Box>

            {/* Action buttons */}
            {canEditDocuments && (
              <Box display="flex" justifyContent="space-between" mt="20px">
                {!isEditable && (
                  <Button
                    variant="contained"
                    color="error"
                    sx={{ 
                       "&.Mui-disabled": {
                      backgroundColor: colors.redAccent, // Keep the same color when disabled
                      opacity: 0.7, // Optionally add some opacity to indicate disabled state
                    },}}
                    onClick={handleDelete}
                    disabled={isDeleting} // Disable button while deleting
                    startIcon={
                      isDeleting ? (
                        <CircularProgress size={20} color="inherit" />
                      ) : null
                    }
                  >
                    {isDeleting ? "A eliminar..." : "Eliminar Documento"}
                  </Button>
                )}
                {isEditable && (
                  <Button
                    onClick={() => {
                      resetForm();
                      setIsEditable(false);
                    }}
                    color="error"
                    sx={{
                      "&.Mui-disabled": {
                        backgroundColor: colors.redAccent, // Keep the same color when disabled
                        style: { color: colors.customTheme.textColor }, // Cor do label para preto
                        opacity: 0.7, // Optionally add some opacity to indicate disabled state
                      },
                    }}
                  >
                    Cancelar Alterações
                  </Button>
                )}
                <Button
                  onClick={() => {
                    if (!isEditable) {
                      setIsEditable(true); // Torna o formulário editável quando clicado no botão "Editar"
                     // console.log("colocado a editar");
                    } else {
                      handleSubmit(); // Submete o formulário se já estiver editando
                    }
                  }}
                  sx={{
                   // mb: "1rem", // Add margin-bottom of 1rem
                    backgroundColor:  colors.customTheme.primary,
                    style: { color: colors.customTheme.textColor }, // Cor do label para preto
                    "&:hover": {
                      backgroundColor:colors.customTheme.darkPrimary, // Hover state color
                    },
                    "&.Mui-disabled": {
                      backgroundColor: colors.customTheme.primary, // Keep the same color when disabled
                      color: "#fff", // Ensure text stays white when disabled
                      opacity: 0.7, // Optionally add some opacity to indicate disabled state
                    },
                  }}
                  variant="contained"
                  disabled={isSubmitting} // Desabilita o botão enquanto o formulário está sendo submetido
                  startIcon={
                    isSubmitting ? (
                      <CircularProgress size={20} color="inherit" />
                    ) : null
                  }
                >
                  {isSubmitting
                    ? "A guardar..."
                    : isEditable
                    ? "Guardar Alterações"
                    : "Editar"}
                </Button>
              </Box>
            )}
          </form>
        )}
      </Formik>
    </Box>
  );
};

const checkoutSchema = yup.object().shape({
  title: yup.string().required("required"),
});

export default Form;
