import { createContext, useState, useMemo } from "react";
import { createTheme } from "@mui/material/styles";

// Updated color design tokens for a more professional and modern palette
export const tokens = (mode) => ({
  ...(mode === "dark"
    ? {
        // Dark theme tokens
        grey: {
          100: "#d1d1d1",
          200: "#b2b2b2",
          300: "#939393",
          400: "#747474",
          500: "#555555",
          600: "#3f3f3f",
          700: "#2a2a2a",
          800: "#1a1a1a",
          900: "#0e0e0e",
        },
        primary: {
          100: "#cfd8e3",
          200: "#9fb0c7",
          300: "#6f88ac",
          400: "#3f6090",
          500: "#1b3b6b", // Dark Blue for primary theme
          600: "#172f57",
          700: "#122444",
          800: "#0d1930",
          900: "#070f1d",
        },
        greenAccent: {
          100: "#d7e8df",
          200: "#afcbbf",
          300: "#87ad9f",
          400: "#5f907f",
          500: "#3b745f", // Muted green accent
          600: "#2f5d4b",
          700: "#234638",
          800: "#182e24",
          900: "#0d1712",
        },
        redAccent: {
          100: "#f4d0d1",
          200: "#eaa2a2",
          300: "#df7374",
          400: "#d54545",
          500: "#c3191a", // Deep red for error actions
          600: "#9d1415",
          700: "#780f10",
          800: "#530a0b",
          900: "#2d0505",
        },
        blueAccent: {
          100: "#d6e4ef",
          200: "#adc9de",
          300: "#83afce",
          400: "#5a94bd",
          500: "#3a769f", // Subtle blue accent for emphasis
          600: "#2e5e80",
          700: "#234761",
          800: "#172f41",
          900: "#0c1821",
        },
        customTheme: {
          primary: "#F1B10F", // Custom primary color
          darkPrimary: "#C18D0C",
          secondary: "#82D0D2", // Custom secondary color
          background: "#eee", // Um tom suave de branco
          darkBackground:"#eee", // Dark background for dark mode
          neutral: "#CACACB", // Neutro cinza claro para uso geral
          textColor: "#000",
          whiteTextColor: "#fff"

        },
      }
    : {
        // Light theme tokens
        grey: {
          100: "#0e0e0e",
          200: "#1a1a1a",
          300: "#2a2a2a",
          400: "#3f3f3f",
          500: "#555555",
          600: "#747474",
          700: "#939393",
          800: "#b2b2b2",
          900: "#d1d1d1",
        },
        primary: {
          100: "#f2f5f8",
          200: "#dfe7ee",
          300: "#c3d0db",
          400: "#a7b9c9",
          500: "#8ba2b6", // Lighter primary blue for light mode
          600: "#6e8391",
          700: "#1b3b6b",
          800: "#324649",
          900: "#142824",
        },
        greenAccent: {
          100: "#e6f1eb",
          200: "#cce2d7",
          300: "#b3d4c2",
          400: "#99c5ad",
          500: "#7fb799", // Softer green accent for light mode
          600: "#66947a",
          700: "#4c715c",
          800: "#334d3d",
          900: "#192a1f",
        },
        redAccent: {
          100: "#fae1e1",
          200: "#f5c4c4",
          300: "#f0a6a6",
          400: "#ec8989",
          500: "#e66b6b", // Bright red accent for light mode
          600: "#b55656",
          700: "#864040",
          800: "#572b2b",
          900: "#291515",
        },
        blueAccent: {
          100: "#e6eef5",
          200: "#ccdce9",
          300: "#b3cadd",
          400: "#99b8d1",
          500: "#7fa6c5", // Muted blue accent for light mode
          600: "#66859e",
          700: "#4c6377",
          800: "#334251",
          900: "#19212a",
        },
        customTheme: {
          primary: "#F1B10F", // Custom primary color
          darkPrimary: "#C18D0C",

          secondary: "#82D0D2", // Custom secondary color
          background: "#eee", // Um tom suave de branco
          darkBackground:"#eee", // Dark background for dark mode
          neutral: "#CACACB", // Neutro cinza claro para uso geral
          textColor: "#000",
          whiteTextColor: "#fff",
          darkTextColor: "#000"


        },
      })
});

// mui theme settings
export const themeSettings = (mode) => {
  const colors = tokens(mode);
  return {
    palette: {
      mode: mode,
      ...(mode === "dark"
        ? {
            primary: {
              main: colors.customTheme.primary, // Custom primary color for both light and dark modes
            },
            secondary: {
              main: colors.customTheme.secondary, // Muted green for secondary actions
            },
          
            background: {
              default: colors.customTheme.background, // Dark, neutral background
            },
             customTheme: {
          primary: "#452f87", // Defina as cores do customTheme também no dark mode, se necessário
        },
          }
        : {
            primary: {
              main: colors.customTheme.primary, // Custom primary color for light mode
            },
            secondary: {
              main: colors.customTheme.secondary, // Custom secondary color for light mode
            },
            background: {
              default: colors.customTheme.background, // Custom background for light mode
            },
          }),
    },


    typography: {
      fontFamily: ["Roboto", "sans-serif"].join(","), // Modern font
      fontSize: 13, // Slightly larger base font for better readability
      h1: {
        fontFamily: ["Roboto", "sans-serif"].join(","),
        fontSize: 40,
        fontWeight: 600, // Stronger emphasis on headings
        color:"000"
      },
      h2: {
        fontFamily: ["Roboto", "sans-serif"].join(","),
        fontSize: 32,
        fontWeight: 600,
        color:"000"

      },
      h3: {
        fontFamily: ["Roboto", "sans-serif"].join(","),
        fontSize: 24,
        fontWeight: 600,
        color:"000"

      },
      h4: {
        fontFamily: ["Roboto", "sans-serif"].join(","),
        fontSize: 20,
        fontWeight: 500,
        color:"000"

      },
      h5: {
        fontFamily: ["Roboto", "sans-serif"].join(","),
        fontSize: 16,
        fontWeight: 500,
        color:"000"

      },
      h6: {
        fontFamily: ["Roboto", "sans-serif"].join(","),
        fontSize: 14,
        fontWeight: 500,
        color:"000"

      },
    },
  };
};

// context for color mode
export const ColorModeContext = createContext({
  toggleColorMode: () => {},
});

export const useMode = () => {
  const [mode, setMode] = useState("dark");

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () =>
        setMode((prev) => (prev === "dark" ? "dark" : "dark")),
    }),
    []
  );

  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  return [theme, colorMode];
};
