import { useState, useEffect, useContext } from "react";
import {
  Box,
  Typography,
  useTheme,
  Button,
  TextField,
  IconButton,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../../theme";
import SuperUserIcon from "@mui/icons-material/SecurityOutlined";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import Header from "../../../components/Header";
import { useNavigate } from "react-router-dom";
import { ValidUserContext } from "../../login/authCheck";
import Form from "../createUser";
import CloseIcon from "@mui/icons-material/Close";

const Users = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { userRoles } = useContext(ValidUserContext);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null); // Estado para o serviço selecionado
  const [drawerOpen, setDrawerOpen] = useState(false); // Estado para controlar o drawer
  const [isCreatingService, setIsCreatingService] = useState(false);
  const [overlayOpen, setOverlayOpen] = useState(false);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await fetch(
          "https://faculdades-3cket-aefml.oa.r.appspot.com/backoffice/users",
          {
            method: "GET",
            headers: {
              Accept: "application/json",
            },
            credentials: "include",
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch users");
        }

        const data = await response.json();
        const users = data.map((user, index) => ({
          id: index + 1,
          name: user.Name,
          email: user.Email,
          access: JSON.parse(user.Role)[0], // Assuming Role is a JSON string
        }));
        setUsers(users);
      } catch (error) {
        console.error("Error fetching users:", error);
        alert("Failed to fetch users. Please try again.");
      }
    };

    fetchUsers();
  }, []);

  const handleRoleUpdate = async (id, newRoles) => {
    try {
      const email = users.find((row) => row.id === id).email;
      const response = await fetch(
        `https://faculdades-3cket-aefml.oa.r.appspot.com/backoffice/changeRoles/${email}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          credentials: "include",
          body: JSON.stringify(newRoles), // Send roles as array in request body
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update roles");
      }

      // console.log(`Roles for user ${email} updated successfully.`);
    } catch (error) {
      console.error("Error updating roles:", error);
      alert("Failed to update roles. Please try again.");
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRows = users.map((row) =>
      row.id === newRow.id ? newRow : row
    );
    setUsers(updatedRows);

    handleRoleUpdate(newRow.id, newRow.access);
    return newRow;
  };

  const columns = [
    { field: "id", headerName: "ID", editable: false, minWidth: 100 },
    {
      field: "name",
      headerName: "Nome",
      minWidth: 200,
      editable: false,
      cellClassName: "name-column--cell",
    },
    {
      field: "email",
      headerName: "Email",
      minWidth: 200,
      editable: false,
      cellClassName: "name-column--cell",
    },
    {
      field: "phone",
      headerName: "Número de telemóvel",
      minWidth: 150,
      editable: false,
    },
    {
      field: "access",
      headerName: "Permissões",
      minWidth: 150,
      editable: false,
      renderCell: ({ value }) => {
        let bgColor;
        let icon;
        if (value === "SU") {
          bgColor = colors.greenAccent[600];
          icon = <SuperUserIcon />;
        } else if (value === "ADMIN") {
          bgColor = colors.greenAccent[700];
          icon = <AdminPanelSettingsOutlinedIcon />;
        } else {
          bgColor = colors.greenAccent[800];
          icon = <LockOpenOutlinedIcon />;
        }
        return (
          <Box
            width="80%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={bgColor}
            borderRadius="4px"
          >
            {icon}
            <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
              {value}
            </Typography>
          </Box>
        );
      },
      renderEditCell: (params) => (
        <TextField
          select
          fullWidth
          value={params.value}
          onChange={(e) => {
            const newValue = e.target.value;

            const currentRole = params.api.getCellValue(params.id, "access");
            if (currentRole !== "SU" && newValue === "SU") {
              alert("You don't have permission to assign 'Super User' role.");
              return;
            }

            params.api.setEditCellValue(
              { id: params.id, field: params.field, value: newValue },
              e
            );
            handleRoleUpdate(params.id, newValue); // Handle role update here
          }}
          SelectProps={{
            native: true,
          }}
        >
          <option value="SU">Super User</option>
          <option value="ADMIN">Admin</option>
          <option value="COACH">Coach</option>
          <option value="NEWS">News</option>
        </TextField>
      ),
    },
  ];

  const handleOpenUserDetails = (params) => {
    setSelectedUser(params.row);
    setIsCreatingService(false); // Ensure we're not creating a new contact
    setOverlayOpen(true);
  };

  const handleCreateService = () => {
    setSelectedUser(null); // Limpa qualquer serviço selecionado
    setIsCreatingService(true); // Modo de criação de serviço
    setOverlayOpen(true);
  };

  const closeOverlay = () => {
    setOverlayOpen(false);
    setSelectedUser(null);
    setIsCreatingService(false); // Reseta o modo de criação de serviço
  };

  const canAddUsers = userRoles.includes("SU") || userRoles.includes("Admin");

  return (
    <Box width="100%">
      <Header
        title="Utilizadores"
        subtitle="Lista de todos os utilizadores do Backoffice "
      />
      <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
        {/* {canAddUsers && (
          <Button
            variant="contained"
            sx={{
              mb: "1rem", // Add margin-bottom of 1rem
              backgroundColor:colors.customTheme.primary , 
              color: "#fff", // Text color based on theme
              "&:hover": {
                backgroundColor:colors.customTheme.darkPrimary, // Hover state color
              },
            }}              onClick={handleCreateService} // Lida com o clique para criar serviço
          >
            Adicionar Utilizador
          </Button>
        )} */}
      </Box>

      <Box display="flex" height="60vh" gap="1rem">
        <Box
          width="100%"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
              fontSize: "14px",
              borderRadius: "8px",
              boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
              backgroundColor: colors.customTheme.primary,
              color: colors.customTheme.textColor,

            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
              color: colors.customTheme.textColor,
            },
            "& .name-column--cell": {
              color: colors.greenAccent[300],
              color: colors.customTheme.textColor,
            },
            "& .MuiDataGrid-columnHeaders": {
              color: colors.customTheme.textColor,
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.customTheme.background,
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.customTheme.primary,
            },
            "& .MuiCheckbox-root": {
              color: `${colors.greenAccent[200]} !important`,
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: colors.customTheme.textColor,
            },
            "& .MuiTablePagination-root" : {
              color: colors.customTheme.textColor,
        },
            "& .MuiSvgIcon-root" : {
              color: colors.customTheme.textColor,
        },

      }}
        >
          <DataGrid
            rows={users}
            columns={columns}
            components={{ Toolbar: GridToolbar }}
            //processRowUpdate={handleOpenUserDetails}
          />
        </Box>

        {overlayOpen && (
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              minWidth: "700px",
              height: "100%",
              background: "rgba(0, 0, 0, 0.7)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 1000,
            }}
          >
            <div
              style={{
                background: colors.customTheme.background,
                padding: "20px",
                borderRadius: "10px",
                width: "100%",
                maxWidth: "500px",
                position: "relative",
              }}
            >
              {" "}
              <Box
                width="100%"
                p={2}
                borderRadius="8px"
                transition="flex 0.3s ease"
                overflow="auto"
                //height="calc(100% - 64px)" // Altura do Drawer (não ocupando a altura total)
              >
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <h2 style={{ color: "#000" }}>
                    {isCreatingService
                      ? "Adicionar Utilizador"
                      : "Detalhes do Utilizador"}
                  </h2>
                  <IconButton onClick={closeOverlay} sx={{ color: "#000" }}>
                    <CloseIcon />
                  </IconButton>
                </Box>

                {/* Renderiza os Detalhes do Serviço se houver um serviço selecionado, caso contrário, o Formulário */}
                {/* {selectedUser ? (
              <ServiceDetails
                serviceDetails={selectedUser} // Passa o serviço selecionado para o formulário
                onClose={closeDrawer} // Passa a função de fechar o drawer para os detalhes
              />
            ) : (
              <Form onClose={closeDrawer} /> // Renderiza o formulário de criação de serviço se nenhum serviço estiver selecionado
            )} */}
                <Form onClose={closeOverlay} />
              </Box>
            </div>
          </div>
        )}
      </Box>
    </Box>
  );
};

export default Users;
