import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { ValidUserContextProvider } from './scenes/login/authCheck';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
    <ValidUserContextProvider>
      <App />
    </ValidUserContextProvider>
    </BrowserRouter>
  </React.StrictMode>
);