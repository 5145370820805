import { createContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

var key = 3;

function encrypt(text) {
  let result = "";

  for (let i = 0; i < text.length; i++) {
    let char = text.charCodeAt(i);
    if (char >= 65 && char <= 90) {
      result += String.fromCharCode(((char - 65 + key) % 26) + 65);
    } else if (char >= 97 && char <= 122) {
      result += String.fromCharCode(((char - 97 + key) % 26) + 97);
    } else if (char >= 48 && char <= 57) {
      result += String.fromCharCode(((char - 48 + key) % 10) + 48);
    } else {
      result += text.charAt(i);
    }
  }
  return result;
}

function decrypt(text) {
  let result = "";

  for (let i = 0; i < text.length; i++) {
    let char = text.charCodeAt(i);
    if (char >= 65 && char <= 90) {
      result += String.fromCharCode(((char - 65 - key + 26) % 26) + 65);
    } else if (char >= 97 && char <= 122) {
      result += String.fromCharCode(((char - 97 - key + 26) % 26) + 97);
    } else if (char >= 48 && char <= 57) {
      result += String.fromCharCode(((char - 48 - key + 10) % 10) + 48);
    } else {
      result += text.charAt(i);
    }
  }
  return result;
}

const ValidUserContext = createContext({
  isLoggedIn: false,
  isLoading: false,
  userRoles: [],
  apiAuthCheck: (enteredEmail, enteredPassword, isInitialCheck = false) => {},
  logout: () => {},
});

const ValidUserContextProvider = (props) => {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [userRoles, setUserRoles] = useState([]);

  useEffect(() => {
    const storedCredentials = localStorage.getItem("userCredentials");

    if (storedCredentials) {
      const { email, encryptedPassword } = JSON.parse(storedCredentials);

      if (email && encryptedPassword) {
        const decryptedPassword = decrypt(encryptedPassword);
        apiAuthCheckHandler(email, decryptedPassword, true);
      } else {
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  }, []);

  const apiAuthCheckHandler = async (
    enteredEmail,
    enteredPassword,
    isInitialCheck = false
  ) => {
    setIsLoading(true);


    const url = "https://faculdades-3cket-aefml.oa.r.appspot.com/backoffice/login";
    const requestBody = { email: enteredEmail, password: enteredPassword };


    try {
      const response = await fetch(url, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(requestBody),
        credentials: "include",
      });


      if (response.ok) {
        const responseData = await response.json();
        localStorage.setItem("userInfo", JSON.stringify(responseData));


        if (!isInitialCheck) {
          const encryptedPassword = encrypt(enteredPassword);
          localStorage.setItem(
            "userCredentials",
            JSON.stringify({ email: enteredEmail, encryptedPassword })
          );
        }

        setUserRoles(responseData.roles);
        setIsLoggedIn(true);
      } else if (response.status === 401) {
        alert("Session expired. Please log in again.");
        logoutHandler();
      } else {
        alert("Authentication failed. Please try again.");
      }
    } catch (error) {
      console.error("Authentication error:", error);
      alert("An error occurred during login. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  const logoutHandler = () => {
    setIsLoggedIn(false);
    setUserRoles([]);
    localStorage.removeItem("userCredentials");
    localStorage.removeItem("userInfo");
    navigate("/login");
  };

  useEffect(() => {
    const idleTimeout = 60 * 60 * 1000; // 1 hour
    let timeout;

    const resetTimer = () => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        alert("You have been logged out due to inactivity.");
        logoutHandler();
      }, idleTimeout);
    };

    const activityEvents = ['mousemove', 'keydown', 'scroll', 'click'];
    activityEvents.forEach(event => window.addEventListener(event, resetTimer));

    return () => {
      clearTimeout(timeout);
      activityEvents.forEach(event => window.removeEventListener(event, resetTimer));
    };
  }, []);

  const context = {
    isLoggedIn,
    isLoading,
    userRoles,
    apiAuthCheck: apiAuthCheckHandler,
    logout: logoutHandler,
  };

  return (
    <ValidUserContext.Provider value={context}>
      {props.children}
    </ValidUserContext.Provider>
  );
};


export { ValidUserContextProvider, ValidUserContext };
