import React, { useState, useEffect, useContext, useRef } from "react";
import { Box, Button, IconButton, useMediaQuery } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import Header from "../../../components/Header";
import { tokens } from "../../../theme";
import { useTheme } from "@mui/material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import CloseIcon from "@mui/icons-material/Close";
import { ValidUserContext } from "../../login/authCheck";
import Form from "../documentDetails/index"; // Import the form for document editing
import AddDocumentForm from "../createAEDocument";
import SnackbarComponent from "../../global/Snackbar";

const AEDocuments = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { userRoles } = useContext(ValidUserContext);
  const [documents, setDocuments] = useState([]);
  const [selectedDocument, setSelectedDocument] = useState(null); // State for selected document
  const [isEditable, setIsEditable] = useState(false); // Adicionando o estado de edição aqui

  const [isCreatingDocument, setIsCreatingDocument] = useState(false); // State to track if we're creating a document
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");


  const [overlayOpen, setOverlayOpen] = useState(false);



  useEffect(() => { }, [isEditable]);

  // Function to fetch documents and update state
  const fetchDocuments = async () => {
    const response = await fetch(
      "https://faculdades-3cket-aefml.oa.r.appspot.com/ae"
    );
    const rawData = await response.json();
    const data = rawData.map((service) => ({
      ...service,
      id: service.ID,
    }));
    setDocuments(data);
  };

  // Fetch documents on mount
  useEffect(() => {
    fetchDocuments();
  }, []);

  const renderFileIcon = (params) => {
    const fileUrl = params.value;
    const fileExtension = fileUrl.split(".").pop().toLowerCase();

    let icon;
    switch (fileExtension) {
      case "pdf":
        icon = (
          <PictureAsPdfIcon
            style={{ color: colors.redAccent[400], fontSize: 40 }}
          />
        );
        break;
      default:
        icon = (
          <PictureAsPdfIcon
            style={{ color: "#000", fontSize: 40 }}
          />
        );
    }

    return (
      <a href={fileUrl} target="_blank" rel="noopener noreferrer">
        {icon}
      </a>
    );
  };

  const columns = [
    { field: "ID", headerName: "ID", minWidth: 100 },
    { field: "Title", headerName: "Nome do Documento", minWidth: 300 },
    {
      field: "Publication_Date",
      headerName: "Publication Date",
      minWidth: 300,
    },
    {
      field: "FileURL",
      headerName: "File",
      width: 100,
      renderCell: renderFileIcon,
    },
  ];

  const handleOpenDocumentDetails = (params) => {
    setSelectedDocument(params.row);
    setIsCreatingDocument(false);
    setOverlayOpen(true);
  };

  const handleCreateDocument = () => {
    setSelectedDocument(null);
    setIsCreatingDocument(true);
    setOverlayOpen(true);
  };



  const closeOverlay = () => {
    setOverlayOpen(false);
    setSelectedDocument(null);
    setIsCreatingDocument(false);
    setIsEditable(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const canAddDocuments =
    userRoles.includes("SU") || userRoles.includes("Admin");

  return (
    <Box width="100%" >
      <Header title="Documentos" subtitle="Lista de todos os documentos" />
      <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
        {canAddDocuments && (
          <Button
            variant="contained"
            onClick={handleCreateDocument}
            sx={{
              mb: "1rem", // Add margin-bottom of 1rem
              backgroundColor: colors.customTheme.primary,
              color: colors.customTheme.textColor, // Text color based on theme
              "&:hover": {
                backgroundColor: colors.customTheme.darkPrimary, // Hover state color
              },
            }}          >
            Adicionar Documento
          </Button>
        )}
      </Box>

      {/* Main Content (Documents Table and Drawer) */}
      <Box
        display="flex"
        height="60vh"

        alignContent="center"
        gap="2rem"
        width="100%"
      //marginBottom="6rem"
      >
        <Box
        // sx={{
        //   flexGrow: 1,
        //   width: drawerOpen ? "60%" : "100%", // When drawer is open, reduce the width of the table
        //   //transition: "width 0.3s ease", // Smooth transition when drawer opens or closes

        // }}
        >
          <Box
            height="100%"
            width="100%"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
                fontSize: "14px",
                borderRadius: "8px",
                boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
                backgroundColor: colors.customTheme.primary,
                color: colors.customTheme.textColor,

              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
                padding: "8px 16px",
                color: colors.customTheme.textColor,

              },
              "& .MuiDataGrid-columnHeaders": {
                color: colors.customTheme.textColor,
                borderBottom: "none",
                fontSize: "14px",
                fontWeight: "bold",
                borderTopLeftRadius: "8px",
                borderTopRightRadius: "8px",
              },
              "& .MuiDataGrid-row:hover": {
                backgroundColor: colors.blueAccent[100],
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: theme.palette.mode === "dark"
                  ? colors.customTheme.darkBackground
                  : colors.customTheme.background
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor:
                  colors.customTheme.primary,
                borderBottomLeftRadius: "8px",
                borderBottomRightRadius: "8px",
                color: colors.customTheme.textColor,
              },
              "& .MuiCheckbox-root": {
                color: `${colors.greenAccent[200]} !important`,
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: colors.customTheme.textColor,
              },
              "& .MuiTablePagination-root": {
                color: colors.customTheme.textColor,
            
              },
              "& .MuiSvgIcon-root" : {
                color: colors.customTheme.textColor,
          },            }}
          >
            <DataGrid
              rows={documents}
              columns={columns}
              components={{ Toolbar: GridToolbar }}
              onRowClick={(params) => {
                if (!overlayOpen || !isEditable) {
                  handleOpenDocumentDetails(params);
                } else if (overlayOpen && isEditable) {
                  showSnackbar(
                    "Termine de editar o documento para puder selecionar outro"
                  );
                }
              }}
            />
          </Box>
          {overlayOpen && (
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                background: "rgba(0, 0, 0, 0.7)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 1000,
              }}
            >
              <div
                style={{
                  background: colors.customTheme.background,
                  padding: "20px",
                  borderRadius: "10px",
                  width: "100%",
                  maxWidth: "500px",
                  position: "relative",
                }}
              >
                <Box
                  //flex={1}
                  //minWidth={isSmallScreen ? "100%" : "40%"} // Full width on small screens
                  p={2}

                  borderRadius="8px"
                  transition="flex 0.3s ease"
                //            overflow="auto"


                >
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <h2
                      style={{
                        color: "#000",
                      }}
                    >
                      {isCreatingDocument
                        ? "Criar Documento"
                        : "Detalhes do Documento"}
                    </h2>
                    <IconButton
                      onClick={closeOverlay}
                      sx={{ color: "#000" }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Box>

                  {/* Render Document Details if a document is selected, otherwise render the Form */}
                  {selectedDocument ? (
                    <Form
                      documentDetails={selectedDocument}
                      onClose={closeOverlay}
                      refreshDocuments={fetchDocuments}
                      isEditable={isEditable}
                      setIsEditable={setIsEditable}
                      showSnackbar={showSnackbar}
                    />
                  ) : (
                    <AddDocumentForm
                      onClose={closeOverlay}
                      refreshDocuments={fetchDocuments}
                      showSnackbar={showSnackbar}
                    />
                  )}
                </Box>
              </div>
            </div>
          )}
        </Box>

        {/* Inline Drawer for Document Details or Create Document Form */}

      </Box>
      <SnackbarComponent
        open={snackbarOpen}
        message={snackbarMessage}
        onClose={handleSnackbarClose}
      />
    </Box>
  );
};

export default AEDocuments;
