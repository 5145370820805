import { Box, colors, IconButton, useTheme } from "@mui/material";
import { useContext } from "react";
import { ColorModeContext } from "../../theme";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import LogoutOutlined from "@mui/icons-material/LogoutOutlined";
import PersonOutline from "@mui/icons-material/PersonOutline";
import { ValidUserContext } from "../login/authCheck";
import { useNavigate } from "react-router-dom";

const Topbar = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const colorMode = useContext(ColorModeContext);
  const validUserContext = useContext(ValidUserContext);

  const handleLogout = () => {
    validUserContext.logout();
  };

  const navigateToProfile = () => {
    navigate("/profile");
  };

  return (
    <Box
      width="100%"
      display="flex"
      justifyContent="flex-end"
      p={2}
    >
      <Box display="flex" alignItems="center">
        {/* ICONS */}
        <Box display="flex" ml={2}>
        

          <IconButton onClick={navigateToProfile}>
            <PersonOutline
              sx={{
                color: "#000",
              }}
            />
          </IconButton>

          <IconButton onClick={handleLogout}>
            <LogoutOutlined
              sx={{
                color: "#000",

              }}
            />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

export default Topbar;
