import { useState, useContext } from "react";
import { Routes, Route } from "react-router-dom";
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import Loading from "./scenes/global/LoadingPage";
import Users from "./scenes/users/usersList";
import News from "./scenes/news/newsList";
import Services from "./scenes/services/servicesList";
import Contacts from "./scenes/contacts/contactsList";
import CreateUser from "./scenes/users/createUser";
import Profile from "./scenes/profile";
import CreateContact from "./scenes/contacts/createContact";
import CreateService from "./scenes/services/createService";
import CreateAEDocument from "./scenes/ae/createAEDocument";
import CreateNews from "./scenes/news/createNews";
import Login from "./scenes/login";
import { Box, CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import { ValidUserContext } from "./scenes/login/authCheck";
import ContactDetails from "./scenes/contacts/contactDetails";
import ServiceDetails from "./scenes/services/serviceDetails";
import NewsDetails from "./scenes/news/newsDetails";
import AEDocuments from "./scenes/ae/aeDocumentsList";
import DocumentDetails from "./scenes/ae/documentDetails";
import ProfilePage from "./scenes/profile";

import footerImage from "./assets/3CKET.png";
import { tokens } from "./theme";

function App() {
  const [theme, colorMode] = useMode();
  const { isLoggedIn, isLoading, userRoles } = useContext(ValidUserContext);
  const [isSidebar, setIsSidebar] = useState(true);
  const colors = tokens(theme.palette.mode);

  let routes = (
    <Routes>
      <Route path="*" element={<Loading />} />
    </Routes>
  );

  // console.log(userRoles);
  if (isLoggedIn) {
    if (userRoles.includes("SU")) {
      routes = (
        <Routes>
          <Route path="/users" element={<Users />} />

          <Route path="/news" element={<News />} />
          <Route path="/createNews" element={<CreateNews />} />
          <Route path="/newsDetails/:id" element={<NewsDetails />} />

          <Route path="/services" element={<Services />} />
          <Route path="/createService" element={<CreateService />} />
          <Route path="/serviceDetails/:id" element={<ServiceDetails />} />

          <Route path="/contacts" element={<Contacts />} />
          <Route path="/createContact" element={<CreateContact />} />
          <Route path="/contactDetails/:id" element={<ContactDetails />} />

          <Route path="/ae" element={<AEDocuments />} />
          <Route path="/createAEDocument" element={<CreateAEDocument />} />
          <Route path="/AEDocumentDetails/:id" element={<DocumentDetails />} />

          <Route path="/createUser" element={<CreateUser />} />
          <Route path="/profile" element={<ProfilePage />} />

          <Route path="/*" element={<News />} />
        </Routes>
      );
    } else if (userRoles.includes("Admin")) {
      routes = (
        <Routes>
          <Route path="/users" element={<Users />} />

          <Route path="/news" element={<News />} />
          <Route path="/createNews" element={<CreateNews />} />
          <Route path="/newsDetails/:id" element={<NewsDetails />} />

          <Route path="/services" element={<Services />} />
          <Route path="/createService" element={<CreateService />} />
          <Route path="/serviceDetails/:id" element={<ServiceDetails />} />

          <Route path="/contacts" element={<Contacts />} />
          <Route path="/createContact" element={<CreateContact />} />
          <Route path="/contactDetails/:id" element={<ContactDetails />} />

          <Route path="/ae" element={<AEDocuments />} />
          <Route path="/createAEDocument" element={<CreateAEDocument />} />
          <Route path="/AEDocumentDetails/:id" element={<DocumentDetails />} />

          <Route path="/createUser" element={<CreateUser />} />
          <Route path="/profile" element={<ProfilePage />} />

          <Route path="/*" element={<News />} />
        </Routes>
      );
    } else {
      if (
        userRoles.includes("News") &&
        userRoles.some((role) => role.startsWith("COACH_"))
      ) {
        routes = (
          <Routes>
            <Route path="/users" element={<Users />} />

            <Route path="/news" element={<News />} />
            <Route path="/createNews" element={<CreateNews />} />
            <Route path="/newsDetails/:id" element={<NewsDetails />} />

            <Route path="/services" element={<Services />} />
            <Route path="/serviceDetails/:id" element={<ServiceDetails />} />

            <Route path="/contacts" element={<Contacts />} />
            <Route path="/contactDetails/:id" element={<ContactDetails />} />

            <Route path="/ae" element={<AEDocuments />} />
            <Route
              path="/AEDocumentDetails/:id"
              element={<DocumentDetails />}
            />
            <Route path="/profile" element={<ProfilePage />} />

            <Route path="/*" element={<News />} />
          </Routes>
        );
      } else if (userRoles.includes("News")) {
        routes = (
          <Routes>
            <Route path="/users" element={<Users />} />

            <Route path="/news" element={<News />} />
            <Route path="/createNews" element={<CreateNews />} />
            <Route path="/newsDetails/:id" element={<NewsDetails />} />

            <Route path="/services" element={<Services />} />
            <Route path="/serviceDetails/:id" element={<ServiceDetails />} />

            <Route path="/contacts" element={<Contacts />} />
            <Route path="/contactDetails/:id" element={<ContactDetails />} />

            <Route path="/ae" element={<AEDocuments />} />
            <Route
              path="/AEDocumentDetails/:id"
              element={<DocumentDetails />}
            />
            <Route path="/profile" element={<ProfilePage />} />

            <Route path="/*" element={<News />} />
          </Routes>
        );
      } else if (userRoles.some((role) => role.startsWith("COACH_"))) {
        routes = (
          <Routes>
            <Route path="/users" element={<Users />} />

            <Route path="/news" element={<News />} />
            <Route path="/newsDetails/:id" element={<NewsDetails />} />

            <Route path="/services" element={<Services />} />
            <Route path="/serviceDetails/:id" element={<ServiceDetails />} />

            <Route path="/contacts" element={<Contacts />} />
            <Route path="/contactDetails/:id" element={<ContactDetails />} />

            <Route path="/ae" element={<AEDocuments />} />
            <Route
              path="/AEDocumentDetails/:id"
              element={<DocumentDetails />}
            />

            <Route path="/*" element={<News />} />
          </Routes>
        );
      }
    }
  } else if (!isLoading) {
    routes = (
      <Routes>
        <Route path="*" element={<Login />} />
      </Routes>
    );
  }

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div
          className="app"
          style={{ display: "flex", height: "100vh", overflow: "hidden" }}
        >
          {isLoggedIn && <Sidebar isSidebar={isSidebar} />}

          <main
            className="content"
            style={{
              flexGrow: 1, // Main content should take up the remaining space
              overflowY: "auto", // Enable vertical scrolling for the content
              position: "relative",
            }}
          >
            {isLoggedIn && <Topbar setIsSidebar={setIsSidebar} />}
            <Box
              sx={{
                height: "100%", // Ensure content fills available height
                padding: 0, // Remove any padding
                margin: 0, // Remove any margin
              }}
            >
              {" "}
              {/* To fill remaining height */}
              {routes}
            </Box>

            <Box
              sx={{
                position: "fixed",
                bottom: 0,
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "1rem 0rem",
                backgroundColor:
                  theme.palette.mode === "dark"
                    ? colors.customTheme.darkBackground
                    : colors.customTheme.background,
                zIndex: "10",
              }}
            >
              <img
                src={footerImage}
                alt="3ckketbeyond"
                style={{ width:"auto", height: "20px" }}
              />
            </Box>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
