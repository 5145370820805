import React, { useState } from "react";
import { Box, Button, TextField } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useNavigate } from "react-router-dom";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import InputAdornment from "@mui/material/InputAdornment";
import { MuiFileInput } from "mui-file-input";
import { useTheme } from "@mui/material";
import { tokens } from "../../../theme";

const AddDocumentForm = ({onClose, refreshDocuments,showSnackbar}) => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [file, setFile] = useState(null);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const handleChanges = (newFile) => {
    setFile(newFile);
  };

  const handleFormSubmit = async (values, setSubmitting) => {
    // Create a new FormData instance
    const formData = new FormData();

    // Append the form values to the FormData
    formData.append("title", values.title);
    formData.append("file", file);

    // Define the URL to which you want to send the form data
    const url = "https://faculdades-3cket-aefml.oa.r.appspot.com/ae/add";

    try {
      // Send the request using the Fetch API
      const response = await fetch(url, {
        method: "POST",
        credentials: "include",
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      // Handle the response data as needed
      const responseData = await response.text();
      // console.log("Success:", responseData);

      refreshDocuments();
      showSnackbar("Documento adicionado com sucesso!"); // Adicione esta linha


     onClose();  // Fecha o drawer após a criação do documento

    } catch (error) {
      console.error("Error:", error);
    }
    setSubmitting(false); // Allows the form to be submitted again
  };




  return (
    <Box m="20px"
    >
      <Formik
        onSubmit={(values, { setSubmitting }) => {
          handleFormSubmit(values, setSubmitting);
        }}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting, // Added to track submission status
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="flex"
              gap="20px"
              flexDirection="column"
             
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Nome"
                sx={{border:"1px solid #000", borderRadius:"4px"}}

                placeholder="Insira o nome do documento"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.title}
                name="title"
                error={!!touched.title && !!errors.title}
                helperText={touched.title && errors.title}
                InputLabelProps={{
                  style: { color: "#000" }, // Cor do label para preto
                }}
                InputProps={{
                  style: { color: "#000" }, // Cor do texto de input para preto
                }}
              />

<MuiFileInput
  InputProps={{
    startAdornment: (
      <InputAdornment position="start">
        <AttachFileIcon sx={{    style: { color: colors.customTheme.textColor }, }} />
      </InputAdornment>
    ),
    style: { color: "#000" }, // Cor do texto de input para preto
  }}
  sx={{
    border: "1px solid #000", // Borda preta
    borderRadius: "4px", // Bordas arredondadas
    color: "#000", // Cor do texto para preto
    '& .MuiInputBase-input': {
      color: "#000", // Garante que o texto de entrada também seja preto
    },
    '& .MuiInputAdornment-root': {
      color: "#000", // Garante que o ícone também seja preto
    },
  }}
  placeholder="Carregar Ficheiro"
  value={file}
  onChange={handleChanges}
/>

            </Box>

            <Box display="flex" justifyContent="end" mt="20px">
              <Button
                type="submit"
                sx={{
                  mb: "1rem", // Add margin-bottom of 1rem
                  backgroundColor: colors.customTheme.primary, 
                  style: { color: colors.customTheme.textColor }, // Cor do label para preto
                  "&:hover": {
                    backgroundColor: colors.customTheme.darkPrimary, // Hover state color
                  },
                  // Style for the disabled state
                  "&.Mui-disabled": {
                    backgroundColor: colors.customTheme.primary, // Keep the same color when disabled
                    style: { color: colors.customTheme.textColor }, // Cor do label para preto
                    opacity: 0.7, // Optionally add some opacity to indicate disabled state
                  },
                }}                         variant="contained"
                disabled={isSubmitting} // Disable the button when submitting
              >
                {isSubmitting ? "A guardar documento..." : "Criar Documento"} {/* Change button text when submitting */}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

const checkoutSchema = yup.object().shape({
  title: yup.string().required("required"),
});

const initialValues = {
  title: "",
  file: null, // Initialize the file as null
};

export default AddDocumentForm;
