import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  List,
  ListItem,
  Link,
  colors,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { tokens } from "../../theme"; // Ajuste o caminho

export default function ProfilePage() {
  const theme = useTheme(); // Access theme colors
  const { name, email, roles } = JSON.parse(localStorage.getItem("userInfo"));
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPasswordFields, setShowPasswordFields] = useState(false); // State to toggle password fields
  const colors = tokens(theme.palette.mode);

  const handleChangePassword = async (e)  => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      alert("As palavras-passe não coincidem!");
      return;
    }

    const requestBody = {
      email: email,
      password: currentPassword,
      newPassword: newPassword,
    };
    const url = `https://faculdades-3cket-aefml.oa.r.appspot.com/backoffice/resetPwd`;

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const responseData = await response.text();
      // console.log("Success:", responseData);

      setShowPasswordFields(false);
    } catch (error) {
      console.error("Error:", error);
    }

    // Implement API call to change the password
   
    setCurrentPassword("");
    setNewPassword("");
    setConfirmPassword("");
  };

  const togglePasswordFields = () => {
    if(showPasswordFields){
      setCurrentPassword("");
      setNewPassword("");
      setConfirmPassword("");
    }

    setShowPasswordFields(!showPasswordFields);
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="80vh"
      width="100%"
    >
      <Box
        p={4}
        borderRadius="12px"
        bgcolor={colors.customTheme.background}
        boxShadow={3}
        minWidth="600px" // Ajusta a largura da caixa
        minHeight="40vh" // Ajusta a altura mínima da caixa
        width="100%"
      >
        <Typography
          variant="h5"
          mb={3}
          sx={{
            fontSize: "26px",
            fontWeight: "bold",
          }}
        >
          Informações do Perfil
        </Typography>

        {/* Display Name, Email and Roles in a clean layout */}
        <Box mb={3} display="flex" justifyContent="space-between">
          <Typography variant="subtitle1">Nome:</Typography>
          <Typography variant="body1">{name}</Typography>
        </Box>

        <Box mb={3} display="flex" justifyContent="space-between">
          <Typography variant="subtitle1">Email:</Typography>
          <Typography variant="body1">{email}</Typography>
        </Box>

        <Box mb={3} display="flex" justifyContent="space-between">
          <Typography variant="subtitle1">Funções:</Typography>
          <Typography variant="body1">
            {roles.join(", ")} {/* Showing roles as comma-separated values */}
          </Typography>
        </Box>

        {/* Button to toggle password change fields */}
        <Button
          variant="contained"
          onClick={togglePasswordFields}
          sx={{
            bgcolor: theme.palette.primary.main,
            "&:hover": { bgcolor: theme.palette.primary.dark },
            width: "100%",
            mb: 3,
          }}
        >
          {showPasswordFields ? "Cancelar" : "Alterar Palavra-passe"}
        </Button>

        {/* Password fields, only visible when the button is clicked */}
        {showPasswordFields && (
          <Box component="form" onSubmit={handleChangePassword}>
            <Box mb={2}>
              <TextField
                fullWidth
                variant="outlined"
                type="password"
                label="Palavra-passe atual"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
                sx={{ border: "1px solid #000", borderRadius: "4px", mb: 2 }}
                InputProps={{
                  style: { color: "#000" }, // Cor do texto de input para preto
                }}
                InputLabelProps={{
                  style: { color: "#000" }, // Cor do label para preto
                }}
              />
              <TextField
                fullWidth
                variant="outlined"
                type="password"
                label="Nova Palavra-passe"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                sx={{ border: "1px solid #000", borderRadius: "4px", mb: 2 }}
                InputProps={{
                  style: { color: "#000" }, // Cor do texto de input para preto
                }}
                InputLabelProps={{
                  style: { color: "#000" }, // Cor do label para preto
                }}
              />
              <TextField
                fullWidth
                variant="outlined"
                type="password"
                label="Confirmar Nova Palavra-passe"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                sx={{ border: "1px solid #000", borderRadius: "4px" }}
                InputProps={{
                  style: { color: "#000" }, // Cor do texto de input para preto
                }}
                InputLabelProps={{
                  style: { color: "#000" }, // Cor do label para preto
                }}
              />
            </Box>

            <Box display="flex" justifyContent="flex-end">
              <Button
                variant="contained"
                type="submit"
                sx={{
                  "&:hover": { bgcolor: theme.palette.primary.dark },
                }}
              >
                Confirmar Alteração
              </Button>
            </Box>

            {/* Reset password link
            <Box mt={2} textAlign="right">
              <Link
                component="button"
                underline="hover"
                onClick={handleResetPasswordEmail}
                sx={{
                  cursor: "pointer",
                  color: "#000",
                  textDecoration:"underline"
                }}
              >
                Redefinir por Email
              </Link>
            </Box> */}
          </Box>
        )}
      </Box>
    </Box>
  );
}
