import React from "react";
import {
  Box,
  Button,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Checkbox,
  ListItemText,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../../components/Header";
import { useNavigate } from "react-router-dom";

const Form = () => {
  const navigate = useNavigate();
  const { name, email, roles } = JSON.parse(localStorage.getItem("userInfo"));

  const rolesOptions = ["SU", "Admin", "Coach", "News"];

  const handleFormSubmit = async (values, setSubmitting) => {
    const data = {
      name: values.firstName,
      email: values.email,
      password: "",
      currentUserRoles: roles,
      roles: values.roles,
    };

   // console.log(roles);
   // console.log(data);
    const url =
      "https://faculdades-3cket-aefml.oa.r.appspot.com/backoffice/register";

    try {
      const response = await fetch(url, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const responseData = await response.text();
      // console.log("Success:", responseData);
    } catch (error) {
      console.error("Error:", error);
    }
    setSubmitting(false);
  };



  return (
    <Box m="20px" minWidth="20vw">
      <Formik
        onSubmit={(values, { setSubmitting }) => {
          handleFormSubmit(values, setSubmitting);
        }}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          setFieldValue,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="flex"
              gap="20px"
              flexDirection="column"
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Nome"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.firstName}
                name="firstName"
                error={!!touched.firstName && !!errors.firstName}
                helperText={touched.firstName && errors.firstName}
              />

              <TextField
                fullWidth
                variant="filled"
                type="email"
                label="Email"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.email}
                name="email"
                error={!!touched.email && !!errors.email}
                helperText={touched.email && errors.email}
              />

              <FormControl fullWidth variant="filled">
                <InputLabel id="roles-label">Permissões</InputLabel>
                <Select
                  labelId="roles-label"
                  id="roles"
                  multiple
                  value={values.roles}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="roles"
                  error={!!touched.roles && !!errors.roles}
                  helperText={touched.roles && errors.roles}
                  renderValue={(selected) => selected.join(", ")}
                >
                  {rolesOptions.map((role) => (
                    <MenuItem key={role} value={role}>
                      <Checkbox checked={values.roles.indexOf(role) > -1} />
                      <ListItemText primary={role} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            <Box display="flex" justifyContent="end" mt="20px">
              <Button type="submit" color="primary" variant="contained">
                Enviar convite
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

const checkoutSchema = yup.object().shape({
  firstName: yup.string().required("Campo obrigatório"),
  email: yup.string().email("Email inválido").required("Campo obrigatório"),
  roles: yup
    .array()
    .min(1, "Pelo menos uma opção necessária")
    .required("Campo obrigatório"),
});

const initialValues = {
  firstName: "",
  email: "", // Initialize email
  roles: [], // Initialize roles as an empty array
  photo: null, // Initialize the photo as null
};

export default Form;