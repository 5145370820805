import React, { useState, useEffect, useContext } from "react";
import { Box, Button, IconButton, useMediaQuery } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Header from "../../../components/Header"; // Ajuste o caminho
import { tokens } from "../../../theme"; // Ajuste o caminho
import { ValidUserContext } from "../../login/authCheck"; // Ajuste o caminho
import CloseIcon from "@mui/icons-material/Close";
import ServiceDetails from "../serviceDetails/index"; // Ajuste o caminho
import CreateService from "../createService/index"; // Ajuste o caminho
import SnackbarComponent from "../../global/Snackbar";

const Services = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { userRoles } = useContext(ValidUserContext);
  const [services, setServices] = useState([]);
  const [selectedService, setSelectedService] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isCreatingService, setIsCreatingService] = useState(false);
  const [overlayOpen, setOverlayOpen] = useState(false);

  const [isEditable, setIsEditable] = useState(false); // Adicionando o estado de edição aqui
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  useEffect(() => {}, [isEditable]);

  const fetchServices = async () => {
    const response = await fetch(
      "https://faculdades-3cket-aefml.oa.r.appspot.com/services"
    );
    const rawData = await response.json();
    const data = rawData.map((service) => ({
      ...service,
      id: service.ID,
      Tags: JSON.parse(service.Tags),
    }));
    setServices(data);
  };

  useEffect(() => {
    fetchServices();
  }, []);

  const columns = [
    { field: "ID", headerName: "ID", width: 100 },
    { field: "Name", headerName: "Nome", width: 300 },
    { field: "Type", headerName: "Tipo", width: 250 },
    {
      field: "ImageURL",
      headerName: "Imagem",
      width: 150,
      renderCell: (params) => (
        <img
          src={params.value}
          alt={params.row.Name}
          style={{ width: 100, height: 60 }}
        />
      ),
    },
  ];

  const handleOpenServiceDetails = (params) => {
    setSelectedService(params.row);
    setIsCreatingService(false);
    setOverlayOpen(true);
  };

  const handleCreateService = () => {
    setSelectedService(null);
    setIsCreatingService(true);
    setOverlayOpen(true);
  };

  const closeOverlay = () => {
    setOverlayOpen(false);
   setSelectedService(null);
    setIsCreatingService(false);
    setIsEditable(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const canAddServices =
    userRoles.includes("SU") || userRoles.includes("Admin");

  return (
    <Box width="100%" overflow="auto">
      <Header title="Serviços" subtitle="Listagem de todos os serviços" />
      <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
        {canAddServices && (
          <Button
            variant="contained"
            sx={{
              mb: "1rem", // Add margin-bottom of 1rem
              backgroundColor: colors.customTheme.primary,
                  color: colors.customTheme.textColor,
                  "&:hover": {
                backgroundColor:colors.customTheme.darkPrimary, // Hover state color
              },
            }}
            onClick={handleCreateService}
          >
            Adicionar Serviço
          </Button>
        )}
      </Box>

      <Box
        display="flex"
       height={"60vh"}
        alignContent="center"
        gap="2rem"
        width="100%"
        //marginBottom="6rem"
      >
        <Box
        // sx={{
        //   flexGrow: 1,
        //   width: isSmallScreen ? "100%" : drawerOpen ? "90%" : "100%", // Ajusta a largura com base na condição
        //   //overflow: "hidden",
        // }}
        >
          <Box
            height="100%"
            width="100%"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
                fontSize: "14px",
                borderRadius: "8px",
                boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
                backgroundColor: colors.customTheme.primary,
              color: colors.customTheme.textColor,


              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
                padding: "8px 16px",
                color: colors.customTheme.textColor,
              },
              "& .MuiDataGrid-columnHeaders": {
                color: colors.customTheme.textColor,
                borderBottom: "none",
                fontSize: "14px",
                fontWeight: "bold",
                borderTopLeftRadius: "8px",
                borderTopRightRadius: "8px",
              },
              "& .MuiDataGrid-row:hover": {
                backgroundColor: colors.blueAccent[100],
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.customTheme.background,
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.customTheme.primary,
                borderBottomLeftRadius: "8px",
                borderBottomRightRadius: "8px",
              },
              "& .MuiCheckbox-root": {
                color: `${colors.greenAccent[200]} !important`,
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: colors.customTheme.textColor,
              },
              "& .MuiTablePagination-root": {
                color: colors.customTheme.textColor,
        
          },         "& .MuiSvgIcon-root" : {
            color: colors.customTheme.textColor,
      },
            }}
          >
            <DataGrid
              rows={services}
              columns={columns}
              components={{ Toolbar: GridToolbar }}
              onRowClick={(params) => {
                if (!drawerOpen || !isEditable) {
                  handleOpenServiceDetails(params);
                } else if (drawerOpen && isEditable) {
                  showSnackbar(
                    "Termine de editar o serviço para puder selecionar outro"
                  ); // Adicione esta linha
                }
              }}
            />
          </Box>
          {overlayOpen && (
           <div
           style={{
             position: "absolute",
             top: 0,
             left: 0,
             width: "100%",
             height: "100%",
             background: "rgba(0, 0, 0, 0.7)",
             display: "flex",
             justifyContent: "center",
             alignItems: "center",
             zIndex: 1000,
             overflowY:"scroll"
           }}
         >
           <div
             style={{
               background: colors.customTheme.background,
               padding: "20px",
               borderRadius: "10px",
               width: "50vw",
               height:"70vh",
               position: "relative",
               overflowY:"scroll",
               overflowX:"hidden"
             }}
           >      
          <Box
            bgcolor={
              theme.palette.mode === "dark"
                ? colors.customTheme.darkBackground
                : colors.customTheme.background
            }
            p={2}
          
            transition="flex 0.3s ease"
            overflow="auto"
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <h2
                style={{
                  color: colors.customTheme.textColor,
                }}
              >
                {isCreatingService ? "Criar Serviço" : "Detalhes do Serviço"}
              </h2>
              <IconButton
                onClick={closeOverlay}
                sx={{                 color: colors.customTheme.textColor,
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>

            {selectedService ? (
              <ServiceDetails
                serviceDetails={selectedService}
                onClose={closeOverlay}
                refreshServices={fetchServices}
                isEditable={isEditable} // Passa o estado atual para o formulário
                setIsEditable={setIsEditable}
                showSnackbar={showSnackbar} // Passa a função aqui
              />
            ) : (
              <CreateService
                onClose={closeOverlay}
                refreshServices={fetchServices}
                showSnackbar={showSnackbar} // Passa a função aqui
              />
            )}
          </Box>
          </div>
          </div>
        )}
        </Box>

       
      </Box>
      <SnackbarComponent
        open={snackbarOpen}
        message={snackbarMessage}
        onClose={handleSnackbarClose}
      />
    </Box>
  );
};

export default Services;
