import React, { useState, useEffect, useRef, useContext } from "react";
import { Box, Button, IconButton, useMediaQuery } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../../theme";
import Header from "../../../components/Header";
import { useTheme } from "@mui/material";
import { ValidUserContext } from "../../login/authCheck";
import NewsDetails from "../newsDetails";
import Form from "../createNews";
import CloseIcon from "@mui/icons-material/Close";
import { parse } from "date-fns";
import { pt } from "date-fns/locale";
import SnackbarComponent from "../../global/Snackbar";

const News = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { userRoles } = useContext(ValidUserContext);
  const [newsData, setNewsData] = useState([]);
  const [selectedNews, setSelectedNews] = useState(null);
  const [isCreatingNews, setIsCreatingNews] = useState(false);
  // const [cursor, setCursor] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  // const fetchNewsCalled = useRef(false);

  const [isEditable, setIsEditable] = useState(false); // Adicionando o estado de edição aqui
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [overlayOpen, setOverlayOpen] = useState(false);

  useEffect(() => {
    //  if (!fetchNewsCalled.current) {
    fetchNews();
    //   fetchNewsCalled.current = true;
    // }
  }, []);

  const parsePublicationDate = (dateString) => {
    // console.log(dateString);

    return parse(dateString, "EEEE, MMMM d, yyyy - HH:mm", new Date(), {
      locale: pt,
    });
  };

  const fetchNews = () => {
    if (isFetching) return;

    setIsFetching(true);
    let uri = "https://faculdades-3cket-aefml.oa.r.appspot.com/news";
    uri += "?amount=25";
    // else uri += "?amount=5";
    // if (cursor != null) uri += "&cursor=" + cursor;
    console.log(uri);
    fetch(uri)
      .then((response) => {
        if (!response.ok) throw new Error(response.statusText);
        return response.json();
      })
      .then((data) => {
       //  console.log(data);
        setNewsData(
          data.news.map((item) => ({
            ...item,
            id: item.ID,
          }))
        );
        // setCursor(data.cursor);
      })
      .catch((error) => console.error("There was an error!", error))
      .finally(() => {
        setIsFetching(false);
      });
  };

  const handleOpenNewsDetails = (params) => {
    setSelectedNews(params.row);
    setIsCreatingNews(false);
    setOverlayOpen(true);
  };

  const handleCreateNews = () => {
    setSelectedNews(null);
    setIsCreatingNews(true);
    setOverlayOpen(true);
  };

  const closeOverlay = () => {
    setOverlayOpen(false);
    setSelectedNews(null);
    setIsCreatingNews(false);
    setIsEditable(false);
  };

  const columns = [
    { field: "ID", headerName: "ID", width: 110, editable: false },
    {
      field: "ImageURL",
      headerName: "Fotografia",
      width: 130,
      renderCell: (params) => (
        <img
          src={params.value}
          alt={params.row.Title}
          style={{ width: 100, height: 60 }}
        />
      ),
    },
    { field: "Title", headerName: "Título", width: 280, editable: true },

    { field: "Author", headerName: "Autor", width: 200, editable: true },
    {
      field: "Publication_Date",
      headerName: "Data de Publicação",
      width: 150,
      editable: false,
      type: "date",
      valueGetter: (params) => parsePublicationDate(params.value),
    },
  ];

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const canAddNews =
    userRoles.includes("SU") ||
    userRoles.includes("Admin") ||
    userRoles.includes("News");

  return (
    <Box width="100%">
      <Header title="Notícias" subtitle="Listagem de todas as notícias" />
      <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
        {canAddNews && (
          <Button
            variant="contained"
            sx={{
              mb: "1rem", // Add margin-bottom of 1rem
              backgroundColor:colors.customTheme.primary , 
              color: colors.customTheme.textColor,
              "&:hover": {
                backgroundColor: colors.customTheme.darkPrimary, // Hover state color
              },
            }}
            onClick={handleCreateNews}
          >
            Adicionar Notícia
          </Button>
        )}
      </Box>
      {/* Container for both table and drawer */}
      <Box
        display="flex"
        height="60vh"
        alignContent="center"
        gap="2rem"
        width="100%"
        //marginBottom="6rem"
      >
        <Box
          sx={{
            flexGrow: 1,
            //overflow:"hidden"
          }}
        >
          {/* Ensure it takes up available space */}
          <Box
            height="100%"
            width="100%"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
                fontSize: "14px",
                borderRadius: "8px",
                boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
                backgroundColor: colors.customTheme.primary,
                color: colors.customTheme.textColor,

              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
                padding: "8px 16px",
                color: colors.customTheme.textColor,

              },
              "& .MuiDataGrid-columnHeaders": {
                color: colors.customTheme.textColor,
                borderBottom: "none",
                fontSize: "14px",
                fontWeight: "bold",
                borderTopLeftRadius: "8px",
                borderTopRightRadius: "8px",
              },
              "& .MuiDataGrid-row:hover": {
                backgroundColor: colors.blueAccent[100],
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor:
                  theme.palette.mode === "dark"
                    ? colors.customTheme.darkBackground
                    : colors.customTheme.background,
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor:  colors.customTheme.primary,
                                borderBottomLeftRadius: "8px",
                borderBottomRightRadius: "8px",
              },
              "& .MuiCheckbox-root": {
                color: `${colors.greenAccent[200]} !important`,
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: colors.customTheme.textColor,
              },
              "& .MuiTablePagination-root": {
                color: colors.customTheme.textColor,
        
          },
          "& .MuiSvgIcon-root" : {
            color: colors.customTheme.textColor,
      },            }}
          >
            <DataGrid
              rows={newsData}
              columns={columns}
              components={{ Toolbar: GridToolbar }}
              onRowClick={handleOpenNewsDetails}
            />
          </Box>
          {overlayOpen && (
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                background: "rgba(0, 0, 0, 0.7)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 1000,
                overflowY: "scroll",
              }}
            >
              <div
                style={{
                  background: colors.customTheme.background,
                  padding: "20px",
                  borderRadius: "10px",
                  width: "50vw",
                  height: "70vh",
                  position: "relative",
                  overflowY: "scroll",
                  overflowX: "hidden",
                }}
              >
                <Box
                  bgcolor={
                    theme.palette.mode === "dark"
                      ? colors.customTheme.darkBackground
                      : colors.customTheme.background
                  }
                  p={2}
                  transition="flex 0.3s ease"
                  overflow="auto"

                  // width={isSmallScreen ? "100%" : "30vw"} // Drawer width
                >
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <h2
                      style={{
                        color: "#000",
                      }}
                    >
                      {isCreatingNews ? "Criar Notícia" : "Detalhes da Notícia"}
                    </h2>
                    <IconButton onClick={closeOverlay} sx={{ color: "#000" }}>
                      <CloseIcon />
                    </IconButton>
                  </Box>

                  {/* Render News Details if a news item is selected, otherwise render the Form */}
                  {selectedNews ? (
                    <NewsDetails
                      newsDetails={selectedNews}
                      onClose={closeOverlay}
                      refreshNews={fetchNews}
                      isEditable={isEditable} // Passa o estado atual para o formulário
                      setIsEditable={setIsEditable}
                      showSnackbar={showSnackbar} // Passa a função aqui
                    />
                  ) : (
                    <Form
                      onClose={closeOverlay}
                      refreshNews={fetchNews}
                      showSnackbar={showSnackbar}
                    />
                  )}
                </Box>
              </div>
            </div>
          )}
        </Box>
      </Box>
      <SnackbarComponent
        open={snackbarOpen}
        message={snackbarMessage}
        onClose={handleSnackbarClose}
      />
    </Box>
  );
};

export default News;
