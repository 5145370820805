import React, { useState, useContext, memo } from "react";
import { Box, Button, TextField,CircularProgress } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../../components/Header";
import { MuiFileInput } from "mui-file-input";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import InputAdornment from "@mui/material/InputAdornment";
import { ValidUserContext } from "../../login/authCheck";
import { useTheme } from "@mui/material";
import { tokens } from "../../../theme";
const MemoizedTextField = memo(TextField);

const NewsDetails = ({ newsDetails, onClose, refreshNews, setIsEditable, isEditable, showSnackbar }) => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const { userRoles } = useContext(ValidUserContext);
  const [file, setFile] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false); // State to track deleting process

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  // Handle file changes
  const handleChanges = (newFile) => {
    setFile(newFile);
  };

  // Form submission logic
  const handleFormSubmit = async (values, { setSubmitting }) => {
    if (!isEditable) {
      setSubmitting(false);
      return;
    }

    const formData = new FormData();

    Object.keys(values).forEach((key) => {
      if (key !== "photo") {
        formData.append(key, values[key]);
      }
    });

    if (file) {
      formData.append("photo", file);
    }

    const url = `https://faculdades-3cket-aefml.oa.r.appspot.com/news/update/${newsDetails.ID}`;

    try {
      const response = await fetch(url, {
        method: "POST",
        body: formData,
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");

      }
      refreshNews();
      showSnackbar("Documento editado com sucesso!"); // Adicione esta linha

      const responseData = await response.text();
      // console.log("Success:", responseData);
    } catch (error) {
      console.error("Error:", error);
    }

    setIsEditable(false);
    setSubmitting(false);
    onClose(); // Fecha o drawer após salvar

  };

  // Handle delete logic
  const handleDelete = async () => {
    setIsDeleting(true); // Set deleting state to true

    const url = `https://faculdades-3cket-aefml.oa.r.appspot.com/news/delete/${newsDetails.ID}`;

    try {
      const response = await fetch(url, {
        method: "DELETE",
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error("Failed to delete the news.");
      }

      // console.log("News deleted successfully");
      refreshNews(); // Atualiza a lista após deletar o documento
      showSnackbar("Documento removido com sucesso!"); // Adicione esta linha

      onClose(); // Close the drawer after deletion
    } catch (error) {
      console.error("Error:", error);
    }
    finally {
      setIsDeleting(false); // Reset deleting state

    }
  };

  // Initial values for form fields
  const initialValues = {
    title: newsDetails.Title || "",
    subtitle: newsDetails.Subtitle || "",
    author: newsDetails.Author || "",
    description: newsDetails.Description || "",
    photo: newsDetails.ImageURL || "",
    publicationDate: newsDetails.Publication_Date || "",
  };

  // Permissions to edit news
  const canEditNews =
    userRoles.includes("SU") ||
    userRoles.includes("Admin") ||
    userRoles.includes("News");

  return (
    <Box >
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
        enableReinitialize
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          resetForm,
          isSubmitting
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="flex"
              width="100%"
              flexDirection="column"
              gap="20px"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              {/* Title */}
              <MemoizedTextField
                fullWidth
                variant="filled"
                type="text"
                                sx={{border:"1px solid #000", borderRadius:"4px"}}

                label="Título"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.title}
                name="title"
                error={!!touched.title && !!errors.title}
                helperText={touched.title && errors.title}
                InputProps={{
                  readOnly: !isEditable,
                  style: { color: "#000" }, // Cor do texto de input para preto
                }}
                InputLabelProps={{
                  style: { color: "#000" }, // Cor do label para preto
                }}              />

              {/* Image */}
              <img
                src={newsDetails.ImageURL}
                alt="Notícia"
                style={{
                  maxWidth: "100%",
                  height: "auto",
                  borderRadius: "8px",
                }}
              />
              {isEditable && (
                <MuiFileInput
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AttachFileIcon />
                      </InputAdornment>
                    ),
                    style: { color: "#000" }, // Cor do texto de input para preto

                  }}
                  placeholder="Escolha uma imagem"
                  value={file}
                  onChange={handleChanges}
                  sx={{
                    border: "1px solid #000", // Borda preta
                    borderRadius: "4px", // Bordas arredondadas
                    color: "#000", // Cor do texto para preto
                    '& .MuiInputBase-input': {
                      color: "#000", // Garante que o texto de entrada também seja preto
                    },
                    '& .MuiInputAdornment-root': {
                      color: "#000", // Garante que o ícone também seja preto
                    },
                  }}
                />
              )}

              {/* Subtitle */}
              <MemoizedTextField
                fullWidth
                variant="filled"
                type="text"
                label="Subtítulo"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.subtitle}
                name="subtitle"
                sx={{border:"1px solid #000", borderRadius:"4px"}}

                error={!!touched.subtitle && !!errors.subtitle}
                helperText={touched.subtitle && errors.subtitle}
                InputProps={{
                  readOnly: !isEditable,
                  style: { color: "#000" }, // Cor do texto de input para preto
                }}
                InputLabelProps={{
                  style: { color: "#000" }, // Cor do label para preto
                }}              />

              {/* Description (Body of the news) */}
              <MemoizedTextField
                fullWidth
                variant="filled"
                multiline
                minRows={6}
                maxRows={60}
                type="text"
                label="Corpo da Notícia"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.description}
                name="description"
                sx={{border:"1px solid #000", borderRadius:"4px"}}
                error={!!touched.description && !!errors.description}
                helperText={touched.description && errors.description}
                InputProps={{
                  readOnly: !isEditable,
                  style: { color: "#000" }, // Cor do texto de input para preto
                }}
                InputLabelProps={{
                  style: { color: "#000" }, // Cor do label para preto
                }}              />

              {/* Author */}
              <MemoizedTextField
                fullWidth
                variant="filled"
                type="text"
                sx={{border:"1px solid #000", borderRadius:"4px"}}

                label="Autor"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.author}
                name="author"
                error={!!touched.author && !!errors.author}
                helperText={touched.author && errors.author}
                InputProps={{
                  readOnly: !isEditable,
                  style: { color: "#000" }, // Cor do texto de input para preto
                }}
                InputLabelProps={{
                  style: { color: "#000" }, // Cor do label para preto
                }}              />

              <TextField
                fullWidth
                variant="filled"
                type="text"
                sx={{border:"1px solid #000", borderRadius:"4px"}}

                label="Date de Publicação"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.publicationDate}
                name="publicationDate"
                error={!!touched.publicationDate && !!errors.publicationDate}
                helperText={touched.publicationDate && errors.publicationDate}
                InputProps={{
                  readOnly: !isEditable,
                  style: { color: "#000" }, // Cor do texto de input para preto
                }}
                InputLabelProps={{
                  style: { color: "#000" }, // Cor do label para preto
                }}              />

              {/* Action Buttons */}
              {canEditNews && (
                <Box display="flex" justifyContent="space-between" mt="20px">
                  {!isEditable && (
                    <Button
                      variant="contained"
                      color="error"
                      onClick={handleDelete}
                      disabled={isDeleting} // Disable button while deleting
                      startIcon={
                        isDeleting ? (
                          <CircularProgress size={20} color="inherit" />
                        ) : null
                      }
                    >
                      {isDeleting ? "A eliminar..." : "Eliminar Notícia"}
                    </Button>
                  )}

                  {isEditable && (
                    <Button
                      onClick={() => {
                        resetForm(); // Resets the form to its initial state
                        setIsEditable(false); // Exits edit mode
                      }}
                      color="error"
                    >
                      Cancelar Alterações
                    </Button>
                  )}

                  <Button
                    onClick={() => {
                      if (!isEditable) {
                        setIsEditable(true); // Torna o formulário editável quando clicado no botão "Editar"
                        // console.log("colocado a editar")
                      } else {
                        handleSubmit(); // Submete o formulário se já estiver editando
                      }
                    }}
                    sx={{
                      backgroundColor: theme.palette.mode === "dark" ? colors.customTheme.primary : colors.customTheme.secondary, 
                      color: theme.palette.mode === "dark" ? "#fff" : "#000", // Text color based on theme
                      "&:hover": {
                        backgroundColor: colors.customTheme.darkPrimary, // Hover state color
                      },
                    }}         
                    variant="contained"
                    disabled={isSubmitting} 
                    startIcon={isSubmitting ? <CircularProgress size={20} color="inherit" /> : null}
                  >
                    {isSubmitting ? "A guardar..." : isEditable ? "Guardar Alterações" : "Editar"}
                  </Button>
                </Box>
              )}
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

// Validation schema for form fields
const checkoutSchema = yup.object().shape({
  title: yup.string().required("Campo obrigatório"),
  subtitle: yup.string().required("Campo obrigatório"),
  author: yup.string().required("Campo obrigatório"),
  description: yup.string().required("Campo obrigatório"),
});

export default NewsDetails;