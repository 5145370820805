import { useState, useEffect } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, Typography, useTheme } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../theme";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import DocumentScannerOutlinedIcon from "@mui/icons-material/DocumentScannerOutlined";
import NewspaperOutlinedIcon from "@mui/icons-material/NewspaperOutlined";
import RoomServiceOutlinedIcon from "@mui/icons-material/RoomServiceOutlined";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import ExpandMoreOutlinedIcon from "@mui/icons-material/Add";
import ExpandLessOutlinedIcon from "@mui/icons-material/MinimizeOutlined";
import logo from '../../assets/logoAE.png'

const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box
      sx={{
        backgroundColor: selected === title ? colors.customTheme.darkPrimary : 'transparent',
        borderRadius: '8px', // Adiciona um leve arredondamento nas bordas
        "&:hover": {
          backgroundColor: colors.customTheme.darkPrimary,
        },
        margin: '10px 0',
      }}


    >




      <MenuItem
        active={selected === title}
        style={{
          color: colors.customTheme.textColor,
        }}
        onClick={() => setSelected(title)}
        icon={icon}
      >
        <Typography>{title}</Typography>
        <Link to={to} />
      </MenuItem>
    </Box>
  );
};

const Sidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Notícias");
  const location = useLocation();

  // This object maps routes to the sidebar item titles
  const pathToTitleMap = {
    // "/users": "Utilizadores",
    "/ae": "Documentos",
    "/news": "Notícias",
    "/services": "Serviços",
    "/contacts": "Contactos",
  };

  useEffect(() => {
    const currentPath = location.pathname;
    const matchedTitle = Object.keys(pathToTitleMap).find((path) =>
      currentPath.startsWith(path)
    );
    if (matchedTitle) {
      setSelected(pathToTitleMap[matchedTitle]);
    }
  }, [location.pathname]);

  return (
    <Box
      sx={{
        "& .pro-sidebar-inner": {
          background: colors.customTheme.primary
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
        },
        "& .pro-inner-item:hover": {
          color: theme.palette.mode === "dark" ? "#fff" : "",
        },
        "& .pro-menu-item.active": {
          color: colors.customTheme.darkPrimary

        },
      }}
    >
      <ProSidebar collapsed={isCollapsed}>

        <Menu iconShape="square">
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            style={{
              color: colors.customTheme.textColor,
            }}
          />

          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              
              backgroundColor: colors.customTheme.primary,
              zIndex: "10",
            }}
          >
            <img
              src={logo}
              alt="3ckketbeyond"
              style={{ width: "80%", height: "auto" }}
            />
          </Box>

          <Box padding={isCollapsed ? undefined : "0% 5%"}>
            {/* <Item
              title="Utilizadores"
              to="/users"
              icon={<PeopleOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            /> */}
            <Item
              title="Documentos"
              to="/ae"
              icon={<DocumentScannerOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Notícias"
              to="/news"
              icon={<NewspaperOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Serviços"
              to="/services"
              icon={<RoomServiceOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Contactos"
              to="/contacts"
              icon={<ContactsOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;
