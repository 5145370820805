import React, { useContext, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  Select,
  TextField,
  CircularProgress, Snackbar,Alert
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import InputAdornment from "@mui/material/InputAdornment";
import { MuiFileInput } from "mui-file-input";
import MenuItem from "@mui/material/MenuItem";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { ValidUserContext } from "../../login/authCheck";
import { useTheme } from "@mui/material";
import { tokens } from "../../../theme";


const ServiceDetails = ({ serviceDetails, onClose, refreshServices, setIsEditable, isEditable, showSnackbar }) => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const { userRoles } = useContext(ValidUserContext);
  const [file, setFile] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false); // State to track deleting process
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const typesOptions = [
    "Restaurante",
    "Cafeteria",
    "Cantina",
    "Bar",
    "Papelaria",
    "Livraria",
    "Serviços Académicos",
    "Centro de Cópias",
    "Apoio Psicológico",
    "Centro de Estudo",
    "Laboratório",
    "Espaço de Co-working",
    "Centro Desportivo",
    "Farmácia",
    "Loja de Material Escolar",
    "Serviços de Informática",
  ];
  
  const tagsOptions = [
    "Almoços",
    "Pequenos Almoços",
    "Lanches",
    "Bebidas Quentes",
    "Bebidas Frias",
    "Comida Rápida",
    "Saladas",
    "Doces e Sobremesas",
    "Serviços Administrativos",
    "Secretaria",
    "Atendimento ao Aluno",
    "Apoio Académico",
    "Consultas",
    "Fotocópias",
    "Material Escolar",
    "Livros",
    "Impressão",
    "Serviços de Estudo",
    "Laboratório",
    "Wi-Fi",
    "Estacionamento",
    "Eventos",
    "Desporto",
    "Saúde",
    "Transporte",
    "Apoio Psicologico",
  ];
  
  const handleFormSubmit = async (values, { setSubmitting }) => {
    if (!isEditable) {
      setSubmitting(false);
      return;
    }

    const formData = new FormData();
    Object.keys(values).forEach((key) => {
      if (key !== "photo") {
        formData.append(key, values[key]);
      }
    });
    if (file) {
      formData.append("photo", file);
    }

    const url = `https://faculdades-3cket-aefml.oa.r.appspot.com/services/update/${serviceDetails.ID}`;

    try {
      const response = await fetch(url, {
        method: "POST",
        credentials: "include",
        body: formData,
      });
      if (!response.ok) { throw new Error("Network response was not ok"); }

      const responseData = await response.text();
      // console.log("Success:", responseData);
      refreshServices();
      showSnackbar("Serviço editado com sucesso!"); // Adicione esta linha


    } catch (error) {
      console.error("Error:", error);
    }

    setIsEditable(false);
    setSubmitting(false);
    onClose();
  };




  const handleDelete = async () => {
    setIsDeleting(true); // Set deleting state to true

    const url = `https://faculdades-3cket-aefml.oa.r.appspot.com/services/delete/${serviceDetails.ID}`;
    try {
      const response = await fetch(url, {
        method: "DELETE",
        credentials: "include",
      });
      if (!response.ok) {
        throw new Error("Failed to delete the contact.");

      }
      // console.log("Service deleted successfully");

      refreshServices();
      showSnackbar("Serviço eliminado com sucesso!"); // Adicione esta linha
      onClose(); // Fecha o drawer após deletar


    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsDeleting(false); // Reset deleting state

    }



  };





  const handleChanges = (newFile) => setFile(newFile);







  const canEditServices =
    userRoles.includes("SU") || userRoles.includes("Admin");

  return (
    <Box m="20px">
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={{
          name: serviceDetails.Name || "",
          type: serviceDetails.Type || "",
          schedule: serviceDetails.Schedule || "",
          address: serviceDetails.Address || "",
          tags: serviceDetails.Tags || "",
          photo: serviceDetails.ImageURL || "",
        }}
        validationSchema={checkoutSchema}
        enableReinitialize
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          setFieldValue,
          handleSubmit,
          resetForm,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="flex"
              gap="20px"
              flexDirection="column"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Nome"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.name}
                name="name"
                error={!!touched.name && !!errors.name}
                sx={{border:"1px solid #000", borderRadius:"4px"}}

                InputProps={{
                  readOnly: !isEditable,
                  style: { color: "#000" }, // Cor do texto do input para preto
                }}
                InputLabelProps={{
                  style: { color: "#000" }, // Cor do label para preto
                }}              />
              <img
                src={serviceDetails.ImageURL}
                alt="Service"
                style={{
                  maxWidth: "50%",
                  margin:"0 auto",
                  height: "auto",
                  borderRadius: "8px",
                }}
              />
        {isEditable && (
  <MuiFileInput
    InputProps={{
      startAdornment: (
        <InputAdornment position="start"> {/* Adicionando a posição aqui */}
          <AttachFileIcon />
        </InputAdornment>
      ),
      style: { color: "#000" }, // Cor do texto do input para preto
    }}
    placeholder="Escolha uma imagem"
    value={file}
    onChange={handleChanges}
    sx={{
      border: "1px solid #000", // Borda preta
      borderRadius: "4px", // Bordas arredondadas
      color: "#000", // Cor do texto para preto
      '& .MuiInputBase-input': {
        color: "#000", // Garante que o texto de entrada também seja preto
      },
      '& .MuiInputAdornment-root': {
        color: "#000", // Garante que o ícone também seja preto
      },
    }}
  />
)}

            

<FormControl fullWidth variant="filled">
  <InputLabel
    id="type-label"
    sx={{
      color: "#000", // Cor do label para preto
      "&.Mui-focused": {
        color: "#000", // Cor do label quando focado
      },
    }}
  >
    Tipo de Serviço
  </InputLabel>
  <Select
    labelId="type-label"
    id="type"
    value={values.type}
    onChange={(event) => setFieldValue("type", event.target.value)}
    onBlur={handleBlur}
    name="type"
    error={!!touched.type && !!errors.type}
    readOnly={!isEditable} // Define o campo como somente leitura quando não for editável
    sx={{
      color: "#000", // Cor do texto selecionado para preto
      backgroundColor: "transparent", // Fundo transparente
      border: "1px solid #000", // Borda preta
      borderRadius: "4px", // Borda arredondada
      "& .MuiSelect-icon": {
        color: "#000", // Cor do ícone de seta para preto
      },
    }}
    MenuProps={{
      PaperProps: {
        sx: {
          backgroundColor: "white", // Fundo branco das opções do menu
          color: "#000", // Cor do texto das opções
        },
      },
    }}
  >
    {typesOptions.map((type) => (
      <MenuItem key={type} value={type} sx={{ color: "#000" }}>
        {type}
      </MenuItem>
    ))}
  </Select>
</FormControl>


              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Horário de Funcionamento"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.schedule}
                name="schedule"
                error={!!touched.schedule && !!errors.schedule}
                sx={{border:"1px solid #000", borderRadius:"4px"}}

                InputProps={{
                  readOnly: !isEditable,
                  style: { color: "#000" }, // Cor do texto do input para preto
                }}
                InputLabelProps={{
                  style: { color: "#000" }, // Cor do label para preto
                }}              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Morada"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.address}
                name="address"
                sx={{border:"1px solid #000", borderRadius:"4px"}}

                error={!!touched.address && !!errors.address}
                InputProps={{
                  readOnly: !isEditable,
                  style: { color: "#000" }, // Cor do texto do input para preto
                }}
                InputLabelProps={{
                  style: { color: "#000" }, // Cor do label para preto
                }}              />
             
             <FormControl fullWidth variant="filled">
  <InputLabel
    id="tags-label"
    sx={{
      color: "#000", // Cor do label
      "&.Mui-focused": {
        color: "#000", // Cor do label quando focado
      },
    }}
  >
    Tags Associados
  </InputLabel>
  <Select
    labelId="tags-label"
    id="tags"
    multiple
    value={values.tags}
    onChange={handleChange}
    onBlur={handleBlur}
    name="tags"
    error={!!touched.tags && !!errors.tags}
    readOnly={!isEditable} // Define o campo como somente leitura quando não for editável
    renderValue={(selected) => selected.join(", ")}
    sx={{
      color: "#000", // Cor do texto selecionado para preto
      backgroundColor: "transparent", // Fundo transparente
      border: "1px solid #000", // Borda preta
      borderRadius: "4px", // Borda arredondada
      "& .MuiSelect-icon": {
        color: "#000", // Cor do ícone de seta para preto
      },
    }}
    MenuProps={{
      PaperProps: {
        sx: {
          backgroundColor: "white", // Fundo branco das opções do menu
          color: "#000", // Cor do texto das opções
        },
      },
    }}
  >
    {tagsOptions.map((tag) => (
      <MenuItem key={tag} value={tag}>
        <Checkbox
          checked={values.tags.indexOf(tag) > -1}
          sx={{
            color: "#000", // Cor da checkbox
            "&.Mui-checked": {
              color: "#000", // Cor da checkbox quando selecionada
            },
          }}
        />
        <ListItemText
          primary={tag}
          sx={{
            color: "#000", // Cor do texto dentro da lista de opções
          }}
        />
      </MenuItem>
    ))}
  </Select>
</FormControl>

            </Box>
            {canEditServices && (
              <Box display="flex" justifyContent="space-between" mt="20px">
                {!isEditable && (
                  <Button
                    variant="contained"
                    color="error"
                    onClick={handleDelete}
                    disabled={isDeleting} // Disable button while deleting
                    startIcon={
                      isDeleting ? (
                        <CircularProgress size={20} color="inherit" />
                      ) : null
                    }
                  >
                    {isDeleting ? "A eliminar..." : "Eliminar Serviço"}
                  </Button>
                )}

                {isEditable && (
                  <Button
                    onClick={() => {
                      resetForm();
                      setIsEditable(false);
                    }}
                    color="error"
                  >
                    Cancelar Alterações
                  </Button>
                )}

                <Button
                  onClick={() => {
                    if (!isEditable) {
                      setIsEditable(true); // Torna o formulário editável quando clicado no botão "Editar"
                      // console.log("colocado a editar")
                    } else {
                      handleSubmit(); // Submete o formulário se já estiver editando
                    }
                  }}
                  sx={{
                    backgroundColor: colors.customTheme.primary, 
                    style: { color: colors.customTheme.textColor }, // Cor do label para preto
                    "&:hover": {
                      backgroundColor: colors.customTheme.darkPrimary, // Hover state color
                    },
                  }}                         variant="contained"
                  disabled={isSubmitting} // Desabilita o botão enquanto o formulário está sendo submetido
                  startIcon={isSubmitting ? <CircularProgress size={20} color="inherit" /> : null}
                >
                  {isSubmitting ? "A guardar..." : isEditable ? "Guardar Alterações" : "Editar"}
                </Button>

              </Box>
            )}
          </form>
        )}
      </Formik>
    </Box>
  );
};

const checkoutSchema = yup.object().shape({
  name: yup.string().required("Campo obrigatório"),
  type: yup.string().required("Campo obrigatório"),
  schedule: yup.string().required("Campo obrigatório"),
});

export default ServiceDetails;
