import React, { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Snackbar, Alert,
  FormControl,
  InputLabel,
  ListItemText,
  Select,
  TextField,
  IconButton
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useNavigate } from "react-router-dom";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import InputAdornment from "@mui/material/InputAdornment";
import CloseIcon from "@mui/icons-material/Close";
import { MuiFileInput } from "mui-file-input";
import { tokens } from "../../../theme";
import { useTheme } from "@mui/material";


const Form = ({ onClose, refreshServices, showSnackbar }) => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode); const [file, setFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);

  const typesOptions = [
    "Restaurante",
    "Cafeteria",
    "Cantina",
    "Bar",
    "Papelaria",
    "Livraria",
    "Serviços Académicos",
    "Centro de Cópias",
    "Apoio Psicológico",
    "Centro de Estudo",
    "Laboratório",
    "Espaço de Co-working",
    "Centro Desportivo",
    "Farmácia",
    "Loja de Material Escolar",
    "Serviços de Informática",
  ];
    const tagsOptions = [
    "Almoços",
    "Pequenos Almoços",
    "Lanches",
    "Bebidas Quentes",
    "Bebidas Frias",
    "Comida Rápida",
    "Saladas",
    "Doces e Sobremesas",
    "Serviços Administrativos",
    "Secretaria",
    "Atendimento ao Aluno",
    "Apoio Académico",
    "Consultas",
    "Fotocópias",
    "Material Escolar",
    "Livros",
    "Impressão",
    "Serviços de Estudo",
    "Laboratório",
    "Wi-Fi",
    "Estacionamento",
    "Eventos",
    "Desporto",
    "Saúde",
    "Transporte",
    "Apoio Psicologico",
  ];
  

 // Estado para o Snackbar
 const [snackbarOpen, setSnackbarOpen] = useState(false);
 const [snackbarMessage, setSnackbarMessage] = useState("");
 const [snackbarSeverity, setSnackbarSeverity] = useState("success");



  const handleChanges = (newFile) => {
    setFile(newFile);
    // Cria uma URL de preview da imagem anexada
    if (newFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(newFile);
    } else {
      setImagePreview(null);
    }
  };

  const handleRemoveImage = () => {
    setFile(null);
    setImagePreview(null);
  };

  const handleFormSubmit = async (values, setSubmitting) => {

    if (values.tags.length === 0) {
      setSnackbarMessage("É necessário adicionar pelo menos uma tag.");
      setSnackbarSeverity("warning");
      setSnackbarOpen(true);
      setSubmitting(false); // Previne o envio do formulário
      return; // Sai da função se não houver tags
    }
  

    try {
      const formData = new FormData();
      formData.append("name", values.name);
      formData.append("type", values.type);
      formData.append("schedule", values.schedule);
      formData.append("address", values.address);
      values.tags.forEach((tag) => {
        formData.append("tags", tag);
      });

      if (file) {
        formData.append("photo", file); // Envia a foto junto ao formulário
      }

      const response = await fetch("https://faculdades-3cket-aefml.oa.r.appspot.com/services/add", {
        method: "POST",
        credentials: "include",
        body: formData,
      });

      if (!response.ok) {
        const errorMessage = `Error: ${response.statusText} (${response.status})`;
        console.error(errorMessage);
        throw new Error(errorMessage);
      }

      const responseData = await response.text();
     //  console.log("Success:", responseData);
      refreshServices();
      showSnackbar("Serviço adicionado com sucesso!");
      onClose();
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Box >
      <Formik
        onSubmit={(values, { setSubmitting }) => {
          handleFormSubmit(values, setSubmitting);
        }}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          setFieldValue,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="flex"
              gap="20px"
              flexDirection="column"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Nome"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.name}
                name="name"
                sx={{ border: "1px solid #000", borderRadius: "4px" }}

                error={!!touched.name && !!errors.name}
                helperText={touched.name && errors.name}
                InputProps={{
                  style: { color: "#000" }, // Cor do texto de input para preto
                }}
                InputLabelProps={{
                  style: { color: "#000" }, // Cor do label para preto
                }}
              />

              {/* Image preview with remove icon */}
              {imagePreview && (
                <Box
                  display="flex"
                  justifyContent="center"
                  mb="10px"
                  position="relative"
                >
                  <img
                    src={imagePreview}
                    alt="Preview"
                    style={{
                      maxWidth: "25vw",
                      height: "auto",
                      borderRadius: "8px",
                    }}
                  />
                  <IconButton
                    size="small"
                    onClick={handleRemoveImage}
                    sx={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      backgroundColor: "rgba(0, 0, 0, 0.6)",
                      color: "#000",
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
              )}

              <MuiFileInput
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AttachFileIcon />
                    </InputAdornment>
                  ),
                  style: { color: "#000" }, // Cor do texto de input para preto

                }}
                placeholder="Carregar imagem"
                value={file}
                onChange={handleChanges}
                sx={{
                  border: "1px solid #000", // Borda preta
                  borderRadius: "4px", // Bordas arredondadas
                  color: "#000", // Cor do texto para preto
                  '& .MuiInputBase-input': {
                    color: "#000", // Garante que o texto de entrada também seja preto
                  },
                  '& .MuiInputAdornment-root': {
                    color: "#000", // Garante que o ícone também seja preto
                  },
                }}
              />

              <FormControl fullWidth variant="filled">
                <InputLabel
                  id="type-label"
                  sx={{
                    color: "#000", // Cor do label para preto
                    "&.Mui-focused": {
                      color: "#000", // Cor do label quando focado
                    },
                  }}
                >
                  Tipo de Serviço
                </InputLabel>
                <Select
                  labelId="type-label"
                  id="type"
                  value={values.type}
                  onChange={(event) => setFieldValue("type", event.target.value)}
                  onBlur={handleBlur}
                  name="type"
                  sx={{
                    color: "#000", // Cor do texto selecionado para preto
                    backgroundColor: "transparent", // Fundo transparente
                    border: "1px solid #000", // Borda preta
                    borderRadius: "4px", // Borda arredondada
                    "& .MuiSelect-icon": {
                      color: "#000", // Cor do ícone de seta para preto
                    },
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        backgroundColor: "white", // Fundo das opções do menu
                        color: "#000", // Cor do texto das opções
                      },
                    },
                  }}
                >
                  {typesOptions.map((type) => (
                    <MenuItem
                      key={type}
                      value={type}
                      sx={{
                        color: "#000", // Cor do texto das opções do menu
                      }}
                    >
                      {type}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>



              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Horário de Funcionamento"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.schedule}
                name="schedule"
                sx={{ border: "1px solid #000", borderRadius: "4px" }}

                error={!!touched.schedule && !!errors.schedule}
                helperText={touched.schedule && errors.schedule}
                InputProps={{
                  style: { color: "#000" }, // Cor do texto de input para preto
                }}
                InputLabelProps={{
                  style: { color: "#000" }, // Cor do label para preto
                }}
              />

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Morada"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.address}
                name="address"
                sx={{ border: "1px solid #000", borderRadius: "4px" }}

                error={!!touched.address && !!errors.address}
                helperText={touched.address && errors.address}
                InputProps={{
                  style: { color: "#000" }, // Cor do texto de input para preto
                }}
                InputLabelProps={{
                  style: { color: "#000" }, // Cor do label para preto
                }}
              />

              <FormControl fullWidth variant="filled">
                <InputLabel
                  id="tags-label"
                  sx={{
                    color: "#000", // Cor do label para preto
                    "&.Mui-focused": {
                      color: "#000", // Cor do label quando focado
                    },
                  }}
                >
                  Tags Associados
                </InputLabel>
                <Select
                  labelId="tags-label"
                  id="tags"
                  multiple
                  value={values.tags}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="tags"
                  renderValue={(selected) => selected.join(", ")}
                  sx={{
                    color: "#000", // Cor do texto selecionado para preto
                    backgroundColor: "transparent", // Fundo transparente
                    border: "1px solid #000", // Borda preta
                    borderRadius: "4px", // Borda arredondada
                    "& .MuiSelect-icon": {
                      color: "#000", // Cor do ícone de seta para preto
                    },
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        backgroundColor: "white", // Fundo branco das opções do menu
                        color: "#000", // Cor do texto das opções
                      },
                    },
                  }}
                >
                  {tagsOptions.map((tag) => (
                    <MenuItem
                      key={tag}
                      value={tag}
                      sx={{
                        color: "#000", // Cor do texto das opções do menu
                      }}
                    >
                      <Checkbox
                        checked={values.tags.indexOf(tag) > -1}
                        sx={{
                          color: "#000", // Cor da checkbox
                          "&.Mui-checked": {
                            color: "#000", // Cor da checkbox quando selecionada
                          },
                        }}
                      />
                      <ListItemText
                        primary={tag}
                        sx={{
                          color: "#000", // Cor do texto dentro da lista de opções
                        }}
                      />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>


            </Box>

            <Box display="flex" justifyContent="end" mt="20px">
              <Button
                type="submit"
                sx={{
                  mb: "1rem", // Add margin-bottom of 1rem
                  backgroundColor: theme.palette.mode === "dark" ? colors.customTheme.primary : colors.customTheme.secondary,
                  style: { color: colors.customTheme.textColor }, // Cor do label para preto
                  "&:hover": {
                    backgroundColor: colors.customTheme.darkPrimary, // Hover state color
                  },
                }} variant="contained"
                disabled={isSubmitting}
              >
                {isSubmitting ? "A guardar documento..." : "Criar Serviço"}
              </Button>
            </Box>
          </form>
        )}
      </Formik>

      <Snackbar
  open={snackbarOpen}
  autoHideDuration={6000}
  onClose={() => setSnackbarOpen(false)}
  anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
  sx={{
    '& .MuiSnackbarContent-root': {
      backgroundColor: 'rgba(0, 0, 0, 0.7)', // Cor de fundo
      color: '#000', // Cor do texto
      borderRadius: '4px', // Bordas arredondadas
    },
  }}
>
  <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%', textAlign: 'center' }}>
    {snackbarMessage}
  </Alert>
</Snackbar>

    </Box>
  );
};

const checkoutSchema = yup.object().shape({
  name: yup.string().required("Campo obrigatório"),
  type: yup.string().required("Campo obrigatório"),
  schedule: yup.string().required("Campo obrigatório"),
  address: yup.string().required("Campo obrigatório"),
});

const initialValues = {
  name: "",
  type: "",
  schedule: "",
  address: "",
  photo: null,
  tags: [],
};

export default Form;
