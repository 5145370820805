import React, { useState } from "react";
import { Box, Button, IconButton, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../../components/Header";
import { useNavigate } from "react-router-dom";
import { MuiFileInput } from "mui-file-input";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import InputAdornment from "@mui/material/InputAdornment";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material";
import { tokens } from "../../../theme";


const Form = ({ onClose, refreshNews, showSnackbar }) => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [file, setFile] = useState(null);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [imagePreview, setImagePreview] = useState(null);

  const handleChanges = (newFile) => {
    setFile(newFile);
    // Create a preview URL for the attached image
    if (newFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(newFile);
    } else {
      setImagePreview(null);
    }
  };

  const handleRemoveImage = () => {
    setFile(null);
    setImagePreview(null);
  };

  const handleFormSubmit = async (values, setSubmitting) => {
    const formData = new FormData();

    formData.append("title", values.title);
    formData.append("subtitle", values.subtitle);
    formData.append("author", values.author);
    formData.append("description", values.description);

    if (file) {
      formData.append("photo", file);
    }

    const url = "https://faculdades-3cket-aefml.oa.r.appspot.com/news/add";

    try {
      const response = await fetch(url, {
        method: "POST",
        body: formData,
        credentials: "include",
        headers: {
          Accept: "application/json", // Adiciona o cabeçalho para aceitar JSON
        },
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      refreshNews();
      showSnackbar("Notícia adicionada com sucesso!"); // Adicione esta linha
      onClose(); // Fecha o drawer após a criação do documento

      const responseData = response.body; // Parseia a resposta como JSON
      //console.log("Success:", responseData);
    } catch (error) {
      console.error("Error:", error);
    }
    setSubmitting(false);
  };

  return (
    <Box m="20px">
      <Formik
        onSubmit={(values, { setSubmitting }) => {
          handleFormSubmit(values, setSubmitting);
        }}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="flex"
              flexDirection="column"
              gap="20px"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              {/* Title */}
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Título"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.title}
                name="title"
                sx={{ border: "1px solid #000", borderRadius: "4px" }}

                error={!!touched.title && !!errors.title}
                helperText={touched.title && errors.title}
                InputProps={{
                  style: { color: "#000" }, // Cor do texto de input para preto
                }}
                InputLabelProps={{
                  style: { color: "#000" }, // Cor do label para preto
                }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Subtítulo"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.subtitle}
                name="subtitle"
                sx={{ border: "1px solid #000", borderRadius: "4px" }}

                error={!!touched.subtitle && !!errors.subtitle}
                helperText={touched.subtitle && errors.subtitle}
                InputProps={{
                  style: { color: "#000" }, // Cor do texto de input para preto
                }}
                InputLabelProps={{
                  style: { color: "#000" }, // Cor do label para preto
                }}
              />

              {imagePreview && (
                <Box display="flex" justifyContent="center" mb="10px" position="relative">
                  <img
                    src={imagePreview}
                    alt="Preview"
                    style={{
                      maxWidth: "25vw",
                      height: "auto",
                      borderRadius: "8px",

                    }}
                  />
                  <IconButton
                    size="small"
                    onClick={handleRemoveImage}
                    sx={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      backgroundColor: "rgba(0, 0, 0, 0.6)",
                      color: "#fff",

                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
              )}

              <MuiFileInput
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {" "}
                      {/* Adiciona `position="start"` */}
                      <AttachFileIcon />
                    </InputAdornment>
                  ),
                  style: { color: "#000" }, // Cor do texto de input para preto

                }}
                sx={{
                  border: "1px solid #000", // Borda preta
                  borderRadius: "4px", // Bordas arredondadas
                  color: "#000", // Cor do texto para preto
                  '& .MuiInputBase-input': {
                    color: "#000", // Garante que o texto de entrada também seja preto
                  },
                  '& .MuiInputAdornment-root': {
                    color: "#000", // Garante que o ícone também seja preto
                  },
                }}

                placeholder="Escolha uma imagem"

                value={file}
                onChange={handleChanges}

              />


              <TextField
                fullWidth
                variant="filled"
                multiline
                minRows={6}
                maxRows={300}
                type="text"
                label="Corpo da notícia"
                placeholder="Insira a notícia"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.description}
                name="description"
                sx={{ border: "1px solid #000", borderRadius: "4px" }}

                error={!!touched.description && !!errors.description}
                helperText={touched.description && errors.description}
                InputProps={{
                  style: { color: "#000" }, // Cor do texto de input para preto
                }}
                InputLabelProps={{
                  style: { color: "#000" }, // Cor do label para preto
                }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Autor"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.author}
                name="author"
                sx={{ border: "1px solid #000", borderRadius: "4px" }}

                error={!!touched.author && !!errors.author}
                helperText={touched.author && errors.author}
                InputProps={{
                  style: { color: "#000" },
                }}
                InputLabelProps={{
                  style: { color: "#000" },
                }}
              />
            </Box>

            <Box display="flex" justifyContent="end" mt="20px">
              <Button
                type="submit"
                sx={{
                  mb: "1rem",
                  backgroundColor: colors.customTheme.primary,
                  style: { color: colors.customTheme.textColor }, // Cor do label para preto
                  "&:hover": {
                    backgroundColor: colors.customTheme.darkPrimary,
                  },
                }} variant="contained"
                disabled={isSubmitting}
              >
                {isSubmitting ? "A guardar notícia..." : "Criar Notícia"}{" "}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

const checkoutSchema = yup.object().shape({
  title: yup.string().required("Campo obrigatório"),
  subtitle: yup.string().required("Campo obrigatório"),
  author: yup.string().required("Campo obrigatório"),
  description: yup.string().required("Campo obrigatório"),
});

const initialValues = {
  title: "",
  subtitle: "",
  author: "",
  description: "",
  photo: null,
};

export default Form;
